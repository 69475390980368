import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from 'semantic-ui-react';

import { State } from '../reducers';
import { Cell } from '../types';

interface IProps {
  className?: string;
}

export default function DownloadButton({ className }: IProps) {
  const canvas = useSelector<State, HTMLCanvasElement>((state) => state.canvas.canvasElement!);
  const view = useSelector<State, Cell>((state) => state.canvas.view);

  const handleDownload = useCallback(() => {
    download(canvas!, view);
  }, [canvas, view]);

  return (
    <Button
      className={className}
      icon="save"
      inverted
      circular
      size="big"
      title="Download current view"
      onClick={handleDownload}
    />
  );
}

/**
 * https://jsfiddle.net/AbdiasSoftware/7PRNN/
 */
function download(canvas: HTMLCanvasElement, view: any) {
  const [x, y] = view.map(Math.round);
  const now = moment.utc(Date.now()).format();
  const filename = `solpixels_(${x},${y})_${now}.png`;

  const dataUrl = canvas.toDataURL();

  downloadDataUrl(dataUrl, filename);
}

function downloadDataUrl(dataurl: string, filename: string) {
  const link = document.createElement('a');
  link.href = dataurl;
  link.download = filename;
  link.click();
}

import { useSelector } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { State } from '../reducers';

interface IProps {
  className?: string;
}

export default function OnlineUsersBox({ className }: IProps) {
  const onlineUsers = useSelector<State, number | undefined>((state) => state.gui.onlineUsers);

  if (onlineUsers == null) {
    return null;
  }

  return (
    <Popup
      content={
        <span>
          This many players are currently online. <i>Exciting!</i>
        </span>
      }
      position="right center"
      size="huge"
      inverted
      trigger={
        <Container className={className}>
          <Icon name="users" size="big" />
          <div className="value">{onlineUsers}</div>
        </Container>
      }
    ></Popup>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .value {
    font-size: 1.3rem;
  }
`;

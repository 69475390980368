import tinycolor from 'tinycolor2';

import { Color, ColorIndex } from './types';

export const SITE_NAME = 'Solpixels';

const NAMED_COLORS: { [key: string]: Color } = {
  white: 'rgb(255, 255, 255)',
  lightgray: 'rgb(228, 228, 228)',
  gray: 'rgb(136, 136, 136)',
  blackish: 'rgb(34, 34, 34)',
  // lightviolet: 'rgb(140, 52, 235)',

  royalblue: 'rgb(42, 75, 215)',
  lightskyblue: 'rgb(157, 175, 255)',
  turquoise: 'rgb(41, 208, 208)',
  darkgreen: 'rgb(29, 105, 20)',
  darkseagreen: 'rgb(129, 197, 122)',
  yellow: 'rgb(255, 238, 51)',
  saddlebrown: 'rgb(129, 74, 25)',
  wheat: 'rgb(233, 222, 187)',
  coral: 'rgb(255, 146, 51)',
  darkorchid: 'rgb(129, 38, 192)',
  firebrick: 'rgb(173, 35, 35)',
  mistyrose: 'rgb(255, 205, 243)',

  // darkorchid: 'rgb(206, 32, 212)',
  // crimson: 'rgb(212, 32, 32)',
  // chocolate: 'rgb(212, 101, 32)',
  // goldenrod: 'rgb(212, 182, 32)',
  // yellowgreen: 'rgb(173, 212, 32)',
  // darkyellowgreen: 'rgb(122, 212, 32)',
  // lightseangreen: 'rgb(32, 212, 143)',
  // darkturquoise: 'rgb(32, 188, 212)',
  // royalblue: 'rgb(32, 107, 212)',
  // darkroyalblue: 'rgb(32, 62, 212)',
  // mediumblue: 'rgb(35, 32, 212)',
  // blueviolet: 'rgb(86, 32, 212)',
};
// sienna: 'rgb(143, 113, 58)',
// saddlebrown: 'rgb(87, 44, 6)',
// darkgreen: 'rgb(18, 99, 19)',
// limegreen: 'rgb(40, 182, 20)',
// dodgerblue: 'rgb(32, 171, 237)',
// mediumblue: 'rgb(1, 20, 210)',
// indigo: 'rgb(71, 13, 166)',
// deeppink: 'rgb(241, 50, 183)',
// red: 'rgb(222, 1, 6)',
// orangered: 'rgb(251, 98, 1)',
// yellow: 'rgb(243, 232, 23)',
// blueviolet2: 'rgb(86, 32, 212)',
// saddlebrown: 'rgb(104, 60, 32)',
// lightsalmon: 'rgb(241, 172, 130)',
// chocolate: 'rgb(241, 114, 32)',
// yellow: 'rgb(244, 232, 10)',
// crimson: 'rgb(211, 20, 57)',
// limegreen: 'rgb(75, 170, 66)',
// seagreen: 'rgb(21, 122, 97)',
// dodgerblue: 'rgb(32, 169, 228)',
// teal: 'rgb(12, 94, 162)',
// darkslateblue: 'rgb(80, 46, 143)',
// deeppink: 'rgb(237, 44, 122)',
// blueviolet2: 'rgb(86, 32, 212)',
// red: 'rgb(235, 12, 12)',
// darkorange: 'rgb(235, 120, 12)',
// yellow: 'rgb(255, 235, 18)',
// gold: 'rgb(235, 216, 12)',
// greenyellow: 'rgb(201, 235, 12)',
// lawngreen: 'rgb(87, 227, 12)',
// forestgreen: 'rgb(12, 153, 26)',
// mediumturqouise: 'rgb(19, 191, 214)',
// mediumblue: 'rgb(19, 55, 214)',
// blueviolet: 'rgb(104, 19, 214)',
// fuchsia: 'rgb(214, 19, 208)',
// blueviolet2: 'rgb(86, 32, 212)',

// darkorchid: 'rgb(206, 32, 212)',
// crimson: 'rgb(212, 32, 32)',
// chocolate: 'rgb(212, 101, 32)',
// goldenrod: 'rgb(212, 182, 32)',
// yellowgreen: 'rgb(173, 212, 32)',
// darkyellowgreen: 'rgb(122, 212, 32)',
// lightseangreen: 'rgb(32, 212, 143)',
// darkturquoise: 'rgb(32, 188, 212)',
// royalblue: 'rgb(32, 107, 212)',
// darkroyalblue: 'rgb(32, 62, 212)',
// mediumblue: 'rgb(35, 32, 212)',
// blueviolet: 'rgb(86, 32, 212)',

export const COLORS: Array<Color> = Object.values(NAMED_COLORS);
// 'rgb(255, 167, 209)', // lightpink
// 'rgb(229, 0, 0)', // red
// 'rgb(229, 149, 0)', // darkorange
// 'rgb(160, 106, 66)', // sienna
// 'rgb(229, 217, 0)', // gold
// 'rgb(148, 224, 68)', //  yellowgreen
// 'rgb(2, 190, 1)', // green
// 'rgb(0, 211, 221)', // darkturquoise
// 'rgb(0, 131, 199)', // darkcyan
// 'rgb(0, 0, 234)', // blue
// 'rgb(207, 110, 228)', // orchid
// 'rgb(130, 0, 128)', // purple

export const COLORS_ABGR: Uint32Array = new Uint32Array(16);

COLORS.forEach((hexString: string, index: number) => {
  const color = tinycolor(hexString);
  const { r, g, b } = color.toRgb();
  COLORS_ABGR[index] = 0xff000000 | (b << 16) | (g << 8) | r;
});

export const MAX_PIXEL_QUEUE_SIZE = 10;

export const TRANSPARENT: ColorIndex = 0;

export const CHUNK_SIZE = 64;
export const CHUNK_BUFFER_SIZE = (CHUNK_SIZE * CHUNK_SIZE) / 2; // each byte holds data for 2 pixels

export const BIG_CHUNK_RADIUS = 7;

export const CANVAS_WIDTH = 6080;
export const CANVAS_HEIGHT = 3392;
// export const CANVAS_WIDTH = 192;
// export const CANVAS_HEIGHT = 128;

export const SOLCANVAS_PAIRS_LENGTH = (CANVAS_WIDTH * CANVAS_HEIGHT) / 2;

if (CANVAS_WIDTH % CHUNK_SIZE !== 0 || CANVAS_HEIGHT % CHUNK_SIZE !== 0) {
  throw new Error('Canvas dimensions not divisible by chunk size');
}

export const CANVAS_AREA = CANVAS_WIDTH * CANVAS_HEIGHT;
export const X_CHUNKS = CANVAS_WIDTH / CHUNK_SIZE;
export const Y_CHUNKS = CANVAS_HEIGHT / CHUNK_SIZE;

export const MIN_X_CHUNK = -Math.floor(X_CHUNKS / 2);
export const MAX_X_CHUNK = Math.ceil(X_CHUNKS / 2) - 1;

export const MIN_Y_CHUNK = -Math.floor(Y_CHUNKS / 2);
export const MAX_Y_CHUNK = Math.ceil(Y_CHUNKS / 2) - 1;

export const CANVAS_MIN_X = CHUNK_SIZE * MIN_X_CHUNK;
export const CANVAS_MAX_X = CHUNK_SIZE * (1 + MAX_X_CHUNK) - 1;
export const CANVAS_MIN_Y = CHUNK_SIZE * MIN_Y_CHUNK;
export const CANVAS_MAX_Y = CHUNK_SIZE * (1 + MAX_Y_CHUNK) - 1;

export const BOUNDARY_WIDTH = 128;

export const BOUNDARY_MIN_X = CANVAS_MIN_X - BOUNDARY_WIDTH;
export const BOUNDARY_MAX_X = CANVAS_MAX_X + BOUNDARY_WIDTH;
export const BOUNDARY_MIN_Y = CANVAS_MIN_Y - BOUNDARY_WIDTH;
export const BOUNDARY_MAX_Y = CANVAS_MAX_Y + BOUNDARY_WIDTH;

export const SECONDS_MILLIS = 1000;
export const MINUTE_MILLIS = 60 * SECONDS_MILLIS;

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  useAudioContextFix,
  useOnlineUsers,
  useResponsive,
  useSolanaPriceFetcher,
  useSolcanvasProgram,
} from '../hooks';
import { State } from '../reducers';
import Palette from './Palette';
import PixelCanvas from './PixelCanvas';
import SideMenu from './SideMenu';

const Main = () => {
  // useAudioContextFix();
  useSolanaPriceFetcher();
  useSolcanvasProgram();
  useOnlineUsers();
  useResponsive();

  const isDesktop = useSelector<State, boolean>((state) => state.gui.isDesktop);

  return (
    <Container>
      <SideMenu />
      <PixelCanvas />
      {isDesktop && <Palette />}
    </Container>
  );
};

const Container = styled.div``;

export default Main;

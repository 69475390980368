import { Action } from '../actions';

export type AudioState = {
  mute: boolean;
};

const initialState: AudioState = {
  mute: true,
};

export default function audio(state: AudioState = initialState, action: Action): AudioState {
  switch (action.type) {
    case 'SET_MUTE':
      return {
        ...state,
        mute: action.mute,
      };

    default:
      return state;
  }
}

import { useSelector } from 'react-redux';
import { Button, Header, Message, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import solpixelsLogo from '../img/logo.svg';
import autoapprove from '../img/autoapprove.png';
import { CANVAS_HEIGHT, CANVAS_WIDTH, MAX_PIXEL_QUEUE_SIZE, SITE_NAME } from '../constants';
import { State } from '../reducers';
import FullPageModal from './FullPageModal';
import Kbd from './Kbd';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export default function HelpModal({ open, onClose }: IProps) {
  const isDesktop = useSelector<State, boolean>((state) => state.gui.isDesktop);

  return (
    <FullPageModal open={open}>
      <Content>
        {isDesktop && <img className="logo" src={solpixelsLogo} alt="Solpixels logo" />}
        <Header as="h1" className="title">
          Welcome to {SITE_NAME}!
        </Header>
        <div className="description">
          Place color pixels on a {CANVAS_WIDTH} x {CANVAS_HEIGHT} px canvas with other players!
          <br />
          Each pixel is stored directly on the{' '}
          <a href="https://solana.com/" rel="nooper noreferrer" target="_blank">
            Solana
          </a>{' '}
          blockchain forever!
          <br />
          ...or at least until someone else paints over it 🤷
          <br />
          🦄 Have fun! 🎉
        </div>

        {isDesktop ? (
          <>
            <Message size="tiny" className="protip">
              <Icon name="info" size="big" />
              <span>
                <i>PROTIP</i>: If you like (and trust) {SITE_NAME} enough, consider turning on the{' '}
                <Popup
                  content={
                    <div>
                      How to turn on the auto-approve feature in Phantom wallet:
                      <br />
                      <br />
                      <img src={autoapprove} width="434" height="604" alt="Turn on auto-approve in Phantom wallet" />
                    </div>
                  }
                  position="right center"
                  size="huge"
                  trigger={<span className="autoapproveTrigger">auto-approve</span>}
                ></Popup>{' '}
                feature in your wallet for a better experience! 🚀
              </span>
            </Message>
            <div className="controls">
              <Header as="h2">Controls</Header>
              <p>
                Pick a color from the palette
                <br />
                Click on canvas to paint
              </p>
              <p>
                Hold <Kbd>ç{/*ctrl*/}</Kbd> or <Kbd>n{/*command*/}</Kbd> to paint up to {MAX_PIXEL_QUEUE_SIZE} pixels at
                a time in one transaction
              </p>
              <p>
                <Kbd>G</Kbd> or <Kbd>w{/*space*/}</Kbd> to toggle grid
              </p>
              <p>
                <Kbd>W</Kbd>
                <Kbd>S</Kbd>
                <Kbd>A</Kbd>
                <Kbd>D</Kbd> or <Kbd>q{/*up*/}</Kbd>
                <Kbd>r{/*down*/}</Kbd>
                <Kbd>s{/*left*/}</Kbd>
                <Kbd>t{/*right*/}</Kbd> or drag mouse to move
              </p>
              <p>
                <Kbd>+</Kbd>
                <Kbd>-</Kbd> or scroll mouse wheel to zoom
              </p>
            </div>
          </>
        ) : (
          <div className="tabletMobile">
            <i>
              Visit the site on a laptop/desktop device to be able to paint pixels.
              <br />
              For now, feel free to explore what other users created!
            </i>
          </div>
        )}
        <Button color="black" size="huge" onClick={onClose}>
          OK!
        </Button>
      </Content>
    </FullPageModal>
  );
}

const Content = styled.div`
  font-size: 1.6rem;
  padding: 3rem;
  max-width: 640px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 6.4rem;
  }

  .title {
    margin-bottom: 2rem;
  }

  .description {
    line-height: 2rem;
  }

  .protip {
    max-width: 500px;
    margin-bottom: 3rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;

    .icon {
      margin-left: 1rem;
    }
  }

  .controls {
    margin-bottom: 3rem;
  }

  .tabletMobile {
    margin: 2rem;
    line-height: 2rem;
  }

  .autoapproveTrigger {
    cursor: pointer;
    color: #9945ff;
  }
`;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as anchor from '@project-serum/anchor';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import idl from '../idl/solcanvas.json';
import { IPaintParams, IPaintParamsPixel, ISolcanvasState } from '../types';
import { SOLANA_CANVAS_PROGRAM_ID, SOLANA_PIXELS_ADDRESS } from '../env';
import { fetchSolcanvasState, receivePixelUpdates, setPaintFn, setWallet } from '../actions';
import { MINUTE_MILLIS } from '../constants';
import { reportPaint } from '../ga';

const keypair = anchor.web3.Keypair.generate();
const defaultWallet = new anchor.Wallet(keypair);

const pixelsPubKey = new anchor.web3.PublicKey(SOLANA_PIXELS_ADDRESS);

export function useSolcanvasProgram() {
  const { connection } = useConnection();
  const wallet = useWallet();
  const dispatch = useDispatch();
  const anchorWallet = useMemo(() => wallet || defaultWallet, [wallet]);
  const [stateFetched, setStateFetched] = useState(false);

  const provider = useMemo(
    () =>
      new anchor.Provider(connection, anchorWallet as any, {
        preflightCommitment: 'confirmed',
      }),
    [anchorWallet, connection]
  );

  const program = useMemo(() => new anchor.Program(idl as any, SOLANA_CANVAS_PROGRAM_ID, provider), [provider]);

  const fetchState = useCallback(async (): Promise<ISolcanvasState> => {
    const programState = await program.account.pixels.fetch(new anchor.web3.PublicKey(SOLANA_PIXELS_ADDRESS));

    return programState as any;
  }, [program.account.pixels]);

  const paint = useCallback(
    async ({ pixels, beneficiary, value }: IPaintParams): Promise<void> => {
      console.log({ pixels, beneficiary: beneficiary.toBase58(), signer: wallet.publicKey?.toBase58() });

      const [first, ...rest] = pixels;

      const instructions = rest.map(({ index, color }) =>
        program.instruction.paint(index, color, {
          accounts: {
            pixels: pixelsPubKey,
            painter: wallet.publicKey,
            beneficiary,
            systemProgram: anchor.web3.SystemProgram.programId,
          },
        })
      );

      try {
        await program.rpc.paint(first.index, first.color, {
          accounts: {
            pixels: pixelsPubKey,
            painter: wallet.publicKey,
            beneficiary,
            systemProgram: anchor.web3.SystemProgram.programId,
          },
          instructions: [
            anchor.web3.SystemProgram.transfer({
              fromPubkey: wallet.publicKey!,
              toPubkey: beneficiary,
              lamports: 0,
            }),
            ...instructions,
          ],
        });

        reportPaint(value);
      } catch (e) {
        // TODO: handle error better
        console.error(e);
      }
    },
    [program.instruction, program.rpc, wallet.publicKey]
  );

  useEffect(() => {
    if (program?.account?.pixels && !stateFetched) {
      setStateFetched(true);
      dispatch(fetchSolcanvasState(fetchState));
    }
  }, [dispatch, fetchState, program?.account?.pixels, stateFetched]);

  useEffect(() => {
    dispatch(setWallet(wallet));
  }, [dispatch, wallet]);

  useEffect(() => {
    dispatch(setPaintFn(paint));
  }, [dispatch, paint]);

  useEffect(() => {
    console.log('LISTENING TO LOGS');
    const listenerId = connection.onLogs(
      new anchor.web3.PublicKey(SOLANA_CANVAS_PROGRAM_ID),
      (event, ctx) => {
        console.log('LOGS', event, ctx);
        const regex = /px ([0-9]+) to color ([0-9]+)/;
        const { logs } = event;
        // const pixels: IPaintParamsPixel[] = [];
        const pixels: IPaintParamsPixel[] = logs
          .filter((line) => regex.test(line))
          .map((pixelUpdateLog) => {
            const [_, index, color] = regex.exec(pixelUpdateLog)!.map(Number);
            return { index, color } as IPaintParamsPixel;
          });
        console.log('DISPATCH PIXEL UPDATES', pixels);
        dispatch(receivePixelUpdates(pixels));
      },
      'processed'
    );
    return () => {
      console.log('REMOVING LISTENER');
      connection.removeOnLogsListener(listenerId);
    };
  }, [connection, dispatch]);
}

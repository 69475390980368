import { Main } from './components';
import GlobalStyle from './GlobalStyle';
import Providers from './Providers';

function App() {
  return (
    <Providers>
      <GlobalStyle />
      <Main />
    </Providers>
  );
}

export default App;

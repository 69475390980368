import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

import { State } from '../reducers';
import { calcPixelPriceUsd } from '../utils';

interface IProps {
  className?: string;
}

export default function PriceBox({ className }: IProps) {
  const priceLamports = useSelector<State, number>((state) => state.solcanvas.priceLamports);
  const solanaPrice = useSelector<State, number>((state) => state.gui.solanaPrice);

  const priceSol = priceLamports / LAMPORTS_PER_SOL;
  const pxPriceUsd = calcPixelPriceUsd(solanaPrice, priceLamports);

  return (
    <Popup
      content={
        <span>
          Price per pixel ({priceSol} SOL). <i>So cheap!</i>
        </span>
      }
      position="right center"
      size="huge"
      inverted
      trigger={
        <Container className={className}>
          <Icon name="dollar" size="big" />
          <div className="value">{pxPriceUsd.toFixed(2)}</div>
        </Container>
      }
    ></Popup>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .value {
    font-size: 1.3rem;
  }
`;

import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import moment from 'moment';

import {
  BIG_CHUNK_RADIUS,
  CANVAS_MAX_X,
  CANVAS_MAX_Y,
  CANVAS_MIN_X,
  CANVAS_MIN_Y,
  CANVAS_WIDTH,
  CHUNK_SIZE,
  MAX_X_CHUNK,
  MAX_Y_CHUNK,
  MIN_X_CHUNK,
  MIN_Y_CHUNK,
} from './constants';
import { Cell, ChunkId } from './types';

/**
 * http://stackoverflow.com/questions/4467539/javascript-modulo-not-behaving
 * @param n
 * @param m
 * @returns {number} remainder
 */
export function mod(n: number, m: number): number {
  return ((n % m) + m) % m;
}

export function distMax([x1, y1]: Cell, [x2, y2]: Cell): number {
  return Math.max(Math.abs(x1 - x2), Math.abs(y1 - y2));
}

export function clamp(n: number, min: number, max: number): number {
  return Math.max(min, Math.min(n, max));
}

export function getChunkOfPixel(pixel: Cell): Cell {
  return pixel.map((x) => Math.floor(x / CHUNK_SIZE)) as any;
}

export function getClampedChunkOfPixel(cell: Cell): Cell {
  const [chunkX, chunkY] = getChunkOfPixel(cell);

  return [clamp(chunkX, MIN_X_CHUNK, MAX_X_CHUNK), clamp(chunkY, MIN_Y_CHUNK, MAX_Y_CHUNK)];
}

export function isPixelWithinBounds([x, y]: Cell): boolean {
  return x >= CANVAS_MIN_X && x <= CANVAS_MAX_X && y >= CANVAS_MIN_Y && y <= CANVAS_MAX_Y;
}

export function isChunkWithinBounds([x, y]: Cell): boolean {
  return x >= MIN_X_CHUNK && x <= MAX_X_CHUNK && y >= MIN_Y_CHUNK && y <= MAX_Y_CHUNK;
}

export function getCenterCorresponding([x, y]: Cell): Cell {
  const distance = 2 * BIG_CHUNK_RADIUS + 1;
  const [fx, fy] = [0, 0];
  const [cx, cy] = [(x - fx) / distance, (y - fy) / distance].map(Math.round);
  return [fx + cx * distance, fy + cy * distance];
}

export function getOffsetOfPixel(x: number, y: number): number {
  const cx = mod(x, CHUNK_SIZE);
  const cy = mod(y, CHUNK_SIZE);
  return cy * CHUNK_SIZE + cx;
}

export function getPixelFromChunkOffset(i: number, j: number, offset: number): Cell {
  const cx = mod(offset, CHUNK_SIZE);
  const cy = Math.floor(offset / CHUNK_SIZE);
  const x = i * CHUNK_SIZE + cx;
  const y = j * CHUNK_SIZE + cy;
  return [x, y];
}

export function getCellInsideChunk(pixel: Cell): Cell {
  // TODO assert is positive!
  return pixel.map((x) => mod(x, CHUNK_SIZE)) as any;
}

export function screenToWorld({
  scale,
  view,
  canvasElement,
  coords,
}: {
  scale: number;
  view: Cell;
  canvasElement: HTMLCanvasElement;
  coords: Cell;
}): Cell {
  const [x, y] = coords;
  const [viewX, viewY] = view;
  const { width, height } = canvasElement;

  return [Math.floor((x - width / 2) / scale + viewX), Math.floor((y - height / 2) / scale + viewY)];
}

export function worldToScreen({
  scale,
  view,
  canvasElement,
  coords,
}: {
  scale: number;
  view: Cell;
  canvasElement: HTMLCanvasElement;
  coords: Cell;
}): Cell {
  const [x, y] = coords;
  const [viewX, viewY] = view;
  const { width, height } = canvasElement;

  return [(x - viewX) * scale + width / 2, (y - viewY) * scale + height / 2];
}

export function durationToString(ms: number, smallest: boolean = false): string {
  const duration = moment.duration(ms);

  let rendered: string;
  if (duration.days()) {
    const clock = moment.utc(ms).format('HH:mm:ss');
    rendered = `${duration.days()}d ${clock}`;
  } else if (duration.hours()) {
    rendered = moment.utc(ms).format('HH:mm:ss');
  } else if (duration.minutes()) {
    rendered = moment.utc(ms).format('mm:ss');
  } else {
    rendered = moment.utc(ms).format(smallest ? 'ss' : 'mm:ss');
  }

  return rendered;
}

export function cellsEqual(a: Cell, b: Cell): boolean {
  if (a === b) return true;
  if (a == null || b == null) return false;

  return a[0] === b[0] && a[1] === b[1];
}

export function randomChoice<T>(list: Array<T>): T {
  return list[Math.floor(Math.random() * list.length)];
}

export function randomDice(p): boolean {
  return Math.random() < p;
}

/**
 * The maximum is exclusive and the minimum is inclusive
 * @param {*} pmin
 * @param {*} pmax
 */
export function getRandomInt(pmin: number, pmax: number): number {
  const min = Math.ceil(pmin);
  const max = Math.floor(pmax);
  const n = Math.floor(Math.random() * (max - min)) + min;
  return n | 0;
}

export function chunkToPairBoundaries(chunkId: ChunkId) {
  const coords = chunkToBoundaries(chunkId);

  return {
    minX: Math.floor(coords.minX / 2),
    maxX: Math.floor(coords.maxX / 2),
    minY: coords.minY,
    maxY: coords.maxY,
  };
}

function chunkToBoundaries([x, y]: Cell) {
  const xDistance = numDistance(x, MIN_X_CHUNK);
  const yDistance = numDistance(y, MIN_Y_CHUNK);

  const minX = xDistance * CHUNK_SIZE;
  const maxX = (xDistance + 1) * CHUNK_SIZE - 1;
  const minY = yDistance * CHUNK_SIZE;
  const maxY = (yDistance + 1) * CHUNK_SIZE - 1;

  return {
    minX,
    maxX,
    minY,
    maxY,
  };
}

function numDistance(n1: number, n2: number) {
  if (n1 > 0 && n2 > 0) {
    return Math.abs(n1 - n2);
  }
  if (n1 < 0 && n2 < 0) {
    return Math.abs(n1 + Math.abs(n2));
  }
  return Math.abs(n1 - n2);
}

export function pairCoordsToPairIndexes(x: number, y: number): { start: number; end: number } {
  const start = x + y * (CANVAS_WIDTH / 2);
  const end = start + CHUNK_SIZE / 2;

  return { start, end };
}

export function pixelCoordsToPixelIndex([x, y]: Cell): number {
  const xDistance = numDistance(x, CANVAS_MIN_X);
  const yDistance = numDistance(y, CANVAS_MIN_Y);

  return yDistance * CANVAS_WIDTH + xDistance;
}

export function pixelIndexToPixelCoords(pixelIndex: number): Cell {
  const x = pixelIndex % CANVAS_WIDTH;
  const y = Math.floor(pixelIndex / CANVAS_WIDTH);

  return [x + CANVAS_MIN_X, y + CANVAS_MIN_Y];
}

export function copyToClipboard(str: string) {
  if (!!navigator.clipboard.writeText) {
    navigator.clipboard.writeText(str);
  } else {
    // older browsers
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}

export function calcPixelPriceUsd(solanaPriceUsd: number, pixelPriceLamports: number): number {
  return (pixelPriceLamports / LAMPORTS_PER_SOL) * solanaPriceUsd;
}

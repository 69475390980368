import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Box from './Box';
import { selectColor } from '../actions';
import { State } from '../reducers';
import { COLORS } from '../constants';
import { ColorIndex } from '../types';

interface IProps {
  className?: string;
}

export default function Palette({ className }: IProps) {
  const selectedColor = useSelector<State, ColorIndex>((state) => state.gui.selectedColor);
  const dispatch = useDispatch();
  const handleSelectColor = useCallback((index: ColorIndex) => dispatch(selectColor(index)), [dispatch]);

  return (
    <Container className={className}>
      <Colors>
        {COLORS.map((color, index) => (
          <ColorItem
            key={index}
            className={selectedColor === index ? 'selected' : ''}
            style={{ backgroundColor: color }}
            onClick={() => handleSelectColor(index as ColorIndex)}
          />
        ))}
      </Colors>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
`;

const Colors = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 49rem;
  height: 4rem;
  line-height: 0;
  border: 5px solid rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem;
`;

const ColorItem = styled.div`
  display: block;
  width: 3rem;
  height: 3rem;
  z-index: 1;
  cursor: pointer;
  margin: 0;
  padding: 0;

  &:hover {
    z-index: 2;
    transform: scale(1.2);
    transition: transform 0.2s ease;
  }
`;

import { Action } from '../actions';

import { ChunkRGB } from '../renderer';
import { Cell, Chunks, ColorIndex, IPixelPlacements } from '../types';
import { clamp, getChunkOfPixel, mod, getRandomInt, pixelIndexToPixelCoords } from '../utils';
import { BIG_CHUNK_RADIUS, CHUNK_SIZE, MAX_PIXEL_QUEUE_SIZE } from '../constants';

export type CanvasState = {
  chunks: Chunks;
  scale: number;
  view: Cell;
  requested: { [key: string]: boolean };
  fetchCount: number;
  pixelsUpdating: IPixelPlacements;
  canvasElement?: HTMLCanvasElement;
  queueingPixels: boolean;
  queuedPixels: IPixelPlacements;
};

const RANDOM_RADIUS = 2000;
function getRandomCenter(): Cell {
  return [getRandomInt(-RANDOM_RADIUS, RANDOM_RADIUS), getRandomInt(-RANDOM_RADIUS, RANDOM_RADIUS)];
}

function getCenter() {
  const url: string = window.location.href;
  const cleanUrl = url.split('#')[0].split('?')[0];
  const path = cleanUrl.split('/').pop() || '';

  if (path.length === 0 || !path.startsWith('@')) {
    // return getRandomCenter();
    return [0, 0];
  }

  try {
    const almost = path.substring(1);
    const [x, y] = almost.split(',').map((num) => parseInt(num, 10));
    return [x, y];
  } catch (error) {
    // return getRandomCenter();
    return [0, 0];
  }
}

const initialState: CanvasState = {
  chunks: {},
  view: getCenter() as any,
  requested: {},
  scale: 4,
  fetchCount: 0,
  pixelsUpdating: {},
  queueingPixels: false,
  queuedPixels: {},
};

const MIN_SCALE = 1;
const MAX_SCALE = 50;

function onChunkBufferReceived(chunks: Chunks, cell: Cell, chunkBuffer: Uint8Array): ChunkRGB {
  const chunkRGB = new ChunkRGB(cell);
  chunkRGB.from(chunkBuffer);
  chunks[chunkRGB.key] = chunkRGB;

  return chunkRGB;
}

export default function canvas(state: CanvasState = initialState, action: Action): CanvasState {
  switch (action.type) {
    case 'PLACE_PIXEL': {
      const { chunks } = state;
      const { coords, color } = action;

      const [cx, cy] = getChunkOfPixel(coords);
      const key = ChunkRGB.getKey([cx, cy]);
      let chunk = chunks[key];
      if (!chunk) {
        chunk = new ChunkRGB([cx, cy]);
        chunks[chunk.key] = chunk;
      }

      const [x, y] = coords;
      chunk.setColor([x - cx * CHUNK_SIZE, y - cy * CHUNK_SIZE], color);
      return {
        ...state,
        chunks,
      };
    }

    case 'SET_SCALE': {
      return {
        ...state,
        scale: clamp(action.scale, MIN_SCALE, MAX_SCALE),
      };
    }

    case 'SET_VIEW': {
      const { view } = action;
      return {
        ...state,
        view,
      };
    }

    case 'REQUEST_CHUNK': {
      const { fetchCount, requested } = state;
      const { center } = action;

      const key = ChunkRGB.getKey(center);
      requested[key] = true;

      return {
        ...state,
        fetchCount: fetchCount + 1,
        requested,
      };
    }

    case 'REQUEST_BIG_CHUNK': {
      const { fetchCount, requested } = state;
      const { center } = action;

      const key = ChunkRGB.getKey(center);
      requested[key] = true;
      console.log('REQUEST_BIG_CHUNK', { requested, key });

      return {
        ...state,
        fetchCount: fetchCount + 1,
        requested,
      };
    }

    case 'RECEIVE_BIG_CHUNK': {
      const { chunks, fetchCount } = state;
      const { center, arrayBuffer } = action;
      const [cx, cy] = center;
      const radius = BIG_CHUNK_RADIUS;

      let start = 0;
      const bufferSize = (CHUNK_SIZE * CHUNK_SIZE) / 2;
      let chunkBuffer: Uint8Array;
      let cell: Cell;

      for (let dy = -radius; dy <= radius; dy += 1) {
        for (let dx = -radius; dx <= radius; dx += 1) {
          cell = [cx + dx, cy + dy];
          console.log('RECEIVE_BIG_CHUNK', { cell, arrayBuffer, start, bufferSize });
          chunkBuffer = new Uint8Array(arrayBuffer, start, bufferSize);
          console.log('RECEIVE_BIG_CHUNK', { chunks, cell, chunkBuffer });
          onChunkBufferReceived(chunks, cell, chunkBuffer);
          start += bufferSize;
        }
      }
      return {
        ...state,
        chunks,
        fetchCount: fetchCount + 1,
      };
    }

    case 'RECEIVE_CHUNK': {
      const { chunks, fetchCount } = state;
      const { center, arrayBuffer } = action;
      const [cx, cy] = center;
      // const radius = BIG_CHUNK_RADIUS;

      const chunkBuffer = new Uint8Array(arrayBuffer);

      onChunkBufferReceived(chunks, center, chunkBuffer);
      // let start = 0;
      // const bufferSize = (CHUNK_SIZE * CHUNK_SIZE) / 2;
      // let chunkBuffer: Uint8Array;
      // let cell: Cell;

      // for (let dy = -radius; dy <= radius; dy += 1) {
      //   for (let dx = -radius; dx <= radius; dx += 1) {
      //     cell = [cx + dx, cy + dy];
      //     console.log('RECEIVE_BIG_CHUNK', { cell, arrayBuffer, start, bufferSize });
      //     chunkBuffer = new Uint8Array(arrayBuffer, start, bufferSize);
      //     console.log('RECEIVE_BIG_CHUNK', { chunks, cell, chunkBuffer });
      //     onChunkBufferReceived(chunks, cell, chunkBuffer);
      //     start += bufferSize;
      //   }
      // }
      return {
        ...state,
        chunks,
        fetchCount: fetchCount + 1,
      };
    }

    case 'AWAIT_PIXEL_UPDATES': {
      const { pixelsUpdating } = state;
      const { pixels } = action;

      const newPixelsUpdating = {
        ...pixelsUpdating,
      };

      pixels.forEach((px) => {
        const pixelKey = ChunkRGB.getKey(px.coords);

        newPixelsUpdating[pixelKey] = px;
      });

      console.log('await updates', newPixelsUpdating);

      return {
        ...state,
        pixelsUpdating: newPixelsUpdating,
      };
    }

    case 'CANCEL_PIXEL_UPDATES': {
      const { pixelsUpdating } = state;
      const { pixels } = action;

      const newPixelsUpdating = { ...pixelsUpdating };

      pixels.forEach((px) => {
        const pixelKey = ChunkRGB.getKey(px.coords);
        delete newPixelsUpdating[pixelKey];
      });

      console.log('cancel updates', newPixelsUpdating);

      return {
        ...state,
        pixelsUpdating: newPixelsUpdating,
      };
    }

    case 'RECEIVE_PIXEL_UPDATE': {
      const { chunks, fetchCount, pixelsUpdating, queuedPixels } = state;
      const { pixelIndex, color } = action;
      const [x, y] = pixelIndexToPixelCoords(pixelIndex);

      const cell = getChunkOfPixel([x, y]);
      const key = ChunkRGB.getKey(cell);
      const chunk = chunks[key];

      // update chunk if seen
      if (chunk) {
        const cx = mod(x, CHUNK_SIZE);
        const cy = mod(y, CHUNK_SIZE);
        chunk.setColor([cx, cy], color);
      }

      // TODO abstract in utils

      const pixelKey = ChunkRGB.getKey([x, y]);
      const newPixelsUpdating = { ...pixelsUpdating };
      delete newPixelsUpdating[pixelKey];

      const newQueuedPixels = { ...queuedPixels };
      delete newQueuedPixels[pixelKey];

      return {
        ...state,
        chunks,
        fetchCount: fetchCount + 1,
        pixelsUpdating: newPixelsUpdating,
        queuedPixels: newQueuedPixels,
      };
    }

    case 'RECEIVE_PIXEL_UPDATES': {
      const { chunks, fetchCount, pixelsUpdating, queuedPixels } = state;
      const { pixels } = action;

      const newPixelsUpdating = { ...pixelsUpdating };
      const newQueuedPixels = { ...queuedPixels };

      pixels.forEach(({ index, color }) => {
        const [x, y] = pixelIndexToPixelCoords(index);

        const cell = getChunkOfPixel([x, y]);
        const key = ChunkRGB.getKey(cell);
        const chunk = chunks[key];

        // update chunk if seen
        if (chunk) {
          const cx = mod(x, CHUNK_SIZE);
          const cy = mod(y, CHUNK_SIZE);
          chunk.setColor([cx, cy], color);
        }

        const pixelKey = ChunkRGB.getKey([x, y]);
        delete newPixelsUpdating[pixelKey];
        delete newQueuedPixels[pixelKey];
      });

      return {
        ...state,
        chunks,
        fetchCount: fetchCount + 1,
        pixelsUpdating: newPixelsUpdating,
        queuedPixels: newQueuedPixels,
      };
    }

    case 'RECEIVE_CHUNK_BUFFER': {
      const { chunks } = state;
      const { cell, chunkBuffer } = action;
      onChunkBufferReceived(chunks as any, cell, chunkBuffer);
      return {
        ...state,
        chunks,
      };
    }

    case 'SET_CANVAS_ELEMENT': {
      return {
        ...state,
        canvasElement: action.canvas,
      };
    }

    case 'SET_QUEUEING_PIXELS': {
      return {
        ...state,
        queueingPixels: action.queueingPixels,
      };
    }

    case 'QUEUE_PIXEL': {
      const { queuedPixels } = state;
      const { coords, color } = action;

      if (Object.keys(queuedPixels).length >= MAX_PIXEL_QUEUE_SIZE) {
        return state;
      }

      const newPixelsPending = { ...queuedPixels };

      const pixelKey = ChunkRGB.getKey(coords);
      newPixelsPending[pixelKey] = { coords, color };

      return {
        ...state,
        queuedPixels: newPixelsPending,
      };
    }

    case 'CLEAR_PIXEL_QUEUE': {
      return {
        ...state,
        queuedPixels: {},
      };
    }

    default:
      return state;
  }
}

export function reportPaint(value: number) {
  tryPushEvent({
    event: 'paint',
    ecommerce: {
      transaction_id: `${new Date().getTime()}_${Math.random()}`,
      value,
      currency: 'USD',
    },
  });
}

function tryPushEvent(event: any) {
  try {
    (window as any).dataLayer.push(event);
  } catch (e) {
    console.error(e);
  }
}

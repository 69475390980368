import styled from 'styled-components';
import solpixelsLogo from '../img/logo.svg';

interface IProps {
  className?: string;
}

export default function MainLogo({ className }: IProps) {
  return (
    <Container className={className}>
      <img className="logo" src={solpixelsLogo} alt="Solpixels logo" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 3.2rem;
    margin: 0.4rem;
  }
`;

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { setShowHelp } from '../actions';

import { State } from '../reducers';
import HelpModal from './HelpModal';

export default function HelpButton({ className }: any) {
  const dispatch = useDispatch();
  const showHelp = useSelector<State, boolean>((state) => state.gui.showHelp);
  const helpSeen = useSelector<State, boolean>((state) => state.gui.helpSeen);

  const handleOpen = useCallback(() => {
    dispatch(setShowHelp(true));
  }, [dispatch]);
  const handleClose = useCallback(() => {
    dispatch(setShowHelp(false));
  }, [dispatch]);

  useEffect(() => {
    if (!helpSeen) {
      handleOpen();
    }
  }, [handleOpen, helpSeen]);

  return (
    <>
      <Button className={className} icon="question" inverted circular size="big" title="Help" onClick={handleOpen} />
      <HelpModal open={showHelp} onClose={handleClose} />
    </>
  );
}

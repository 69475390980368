import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { setShowGrid, setMute, setAutoZoomIn } from '../actions';
import { State } from '../reducers';
import FullPageModal from './FullPageModal';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export default function SettingsModal({ open, onClose }: IProps) {
  const dispatch = useDispatch();
  const mute = useSelector<State, boolean>((state) => state.audio.mute);
  const { showGrid, autoZoomIn } = useSelector<State, { showGrid: boolean; autoZoomIn: boolean }>((state) => ({
    showGrid: state.gui.showGrid,
    autoZoomIn: state.gui.autoZoomIn,
  }));

  const handleToggleMute = useCallback(() => {
    dispatch(setMute(!mute));
  }, [dispatch, mute]);
  const handleToggleGrid = useCallback(() => {
    dispatch(setShowGrid(!showGrid));
  }, [dispatch, showGrid]);
  const handleToggleAutoZoomIn = useCallback(() => {
    dispatch(setAutoZoomIn(!autoZoomIn));
  }, [autoZoomIn, dispatch]);

  return (
    <FullPageModal open={open}>
      <Content>
        <Header as="h1" className="title">
          Settings
        </Header>
        <div className="checkboxes">
          <Checkbox label="Show grid" onChange={handleToggleGrid} checked={showGrid} />
          {/* <Checkbox label="Mute sounds" onChange={handleToggleMute} checked={mute} /> */}
          <Checkbox
            label="Zoom in instead of placing a pixel if you are zoomed out too much"
            onChange={handleToggleAutoZoomIn}
            checked={autoZoomIn}
          />
        </div>
        <Button color="black" size="huge" onClick={onClose}>
          Close
        </Button>
      </Content>
    </FullPageModal>
  );
}

const Content = styled.div`
  font-size: 1.4rem;
  padding: 3rem;
  max-width: 640px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 2rem;
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    text-align: left;
    gap: 2rem;
    margin-bottom: 4rem;

    label {
      font-size: 1.6rem;

      &::before,
      &::after {
        font-size: 2rem;
      }
    }
  }
`;

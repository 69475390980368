import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { configureStore } from './store';
import SolanaProviders from './SolanaProviders';

interface IProps {
  children: React.ReactNode;
}

const { store, persistor } = configureStore();

export default function Providers({ children }: IProps) {
  return (
    <Provider store={store}>
      <SolanaProviders>
        <PersistGate loading={null} persistor={persistor}>
          {children}
        </PersistGate>
      </SolanaProviders>
    </Provider>
  );
}

// import * as swal from 'sweetalert2';
// import Fingerprint2 from 'fingerprintjs2';
import { WalletContextState } from '@solana/wallet-adapter-react';

import { ChunkRGB } from '../renderer';
import { Cell, ColorIndex, IPaintParamsPixel, IPixelPlacement, ISolcanvasState, PaintFn } from '../types';
import {
  calcPixelPriceUsd,
  chunkToPairBoundaries,
  getCellInsideChunk,
  getChunkOfPixel,
  isChunkWithinBounds,
  pairCoordsToPairIndexes,
  pixelCoordsToPixelIndex,
} from '../utils';
import { Action, ThunkAction, PromiseAction, Dispatch } from './types';
import { State } from '../reducers';

export * from './types';

export function toggleGrid(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(setShowGrid(!state.gui.showGrid));
  };
}

export function setShowGrid(showGrid: boolean): Action {
  return {
    type: 'SET_SHOW_GRID',
    showGrid,
  };
}

export function toggleAutoZoomIn(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(setAutoZoomIn(!state.gui.autoZoomIn));
  };
}

export function setAutoZoomIn(autoZoomIn: boolean): Action {
  return {
    type: 'SET_AUTO_ZOOM_IN',
    autoZoomIn,
  };
}

export function toggleMute(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(setMute(!state.audio.mute));
  };
}

export function setMute(mute: boolean): Action {
  return {
    type: 'SET_MUTE',
    mute,
  };
}

export function setHover(hover: Cell): Action {
  return {
    type: 'SET_HOVER',
    hover,
  };
}

export function unsetHover(): Action {
  return {
    type: 'UNSET_HOVER',
  };
}

export function selectColor(color: ColorIndex): Action {
  return {
    type: 'SELECT_COLOR',
    color,
  };
}

export function placePixel(coords: Cell, color: ColorIndex): Action {
  return {
    type: 'PLACE_PIXEL',
    coords,
    color,
  };
}

export function tryPlacePixel(coords: Cell, color: ColorIndex): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { queueingPixels } = state.canvas;
    const { isDesktop } = state.gui;

    if (!isDesktop) {
      return;
    }

    if (queueingPixels) {
      return dispatch(queuePixel(coords, color));
    }
    const { wallet } = state.solcanvas;

    if (!wallet.connected) {
      console.log('not connected');
      dispatch(queuePixel(coords, color));
      return dispatch(setShowConnect(true));
    }

    dispatch(requestPlacePixels([{ coords, color }]));
  };
}

export function tryFlushPixelQueue(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { queuedPixels } = state.canvas;

    const newPixels = Object.values(queuedPixels).filter((px) => !isSameColorIn(state, px.coords, px.color));

    if (newPixels.length > 0) {
      const { wallet } = state.solcanvas;

      if (!wallet.connected) {
        console.log('not connected');
        return dispatch(setShowConnect(true));
      }

      dispatch(requestPlacePixels(newPixels));
    }

    dispatch(clearPixelQueue());
  };
}

export function requestPlacePixels(pixels: IPixelPlacement[]): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const { paint, beneficiary, priceLamports } = state.solcanvas;
    const { solanaPrice } = state.gui;

    const pixelsToPaint: IPaintParamsPixel[] = pixels.map((px) => ({
      index: pixelCoordsToPixelIndex(px.coords),
      color: px.color,
    }));

    const value = calcPixelPriceUsd(solanaPrice, priceLamports) * pixelsToPaint.length;

    try {
      dispatch(awaitPixelUpdates(pixels));
      await paint({ pixels: pixelsToPaint, beneficiary, value });
    } finally {
      dispatch(cancelPixelUpdates(pixels));
    }
  };
}

function isSameColorIn(state: any, coords: Cell, color: ColorIndex): boolean {
  const { chunks } = state.canvas;
  const [cx, cy] = getChunkOfPixel(coords);
  const key = ChunkRGB.getKey([cx, cy]);
  // const chunk = chunks.get(key);
  const chunk = chunks[key];
  if (!chunk) {
    // estrenamos chunk!
    return color === 0; // default color is white
  }

  // getColor
  return chunk.hasColorIn(getCellInsideChunk(coords), color);
}

export function setView(view: Cell): Action {
  return {
    type: 'SET_VIEW',
    view,
  };
}

export function move([dx, dy]: Cell): ThunkAction {
  return (dispatch, getState) => {
    const { view } = getState().canvas;

    const [x, y] = view;
    dispatch(setView([x + dx, y + dy]));
  };
}

export function moveDirection([vx, vy]: Cell): ThunkAction {
  // TODO check direction is unitary vector
  return (dispatch, getState) => {
    const { scale } = getState().canvas;

    const speed = 100.0 / scale;
    dispatch(move([speed * vx, speed * vy]));
  };
}

export function moveNorth(): ThunkAction {
  return (dispatch) => {
    dispatch(moveDirection([0, -1]));
  };
}

export function moveWest(): ThunkAction {
  return (dispatch) => {
    dispatch(moveDirection([-1, 0]));
  };
}

export function moveSouth(): ThunkAction {
  return (dispatch) => {
    dispatch(moveDirection([0, 1]));
  };
}

export function moveEast(): ThunkAction {
  return (dispatch) => {
    dispatch(moveDirection([1, 0]));
  };
}

export function setScale(scale: number): Action {
  return {
    type: 'SET_SCALE',
    scale,
  };
}

export function zoomIn(): ThunkAction {
  return (dispatch, getState) => {
    const { scale } = getState().canvas;
    dispatch(setScale(scale * 1.1));
  };
}

export function zoomOut(): ThunkAction {
  return (dispatch, getState) => {
    const { scale } = getState().canvas;
    dispatch(setScale(scale / 1.1));
  };
}

export function receiveChunkBuffer(cell: Cell, chunkBuffer: Uint8Array): Action {
  return {
    type: 'RECEIVE_CHUNK_BUFFER',
    cell,
    chunkBuffer,
  };
}

function requestChunk(center: Cell): Action {
  return {
    type: 'REQUEST_CHUNK',
    center,
  };
}

function receiveChunk(center: Cell, arrayBuffer: ArrayBuffer): Action {
  return {
    type: 'RECEIVE_CHUNK',
    center,
    arrayBuffer,
  };
}

export function fetchChunk(center: Cell): PromiseAction {
  const ret = async (dispatch: any, getState: () => State) => {
    if (isChunkWithinBounds(center)) {
      dispatch(requestChunk(center));

      const { pairs } = getState().solcanvas;

      const boundaries = chunkToPairBoundaries(center);

      let pairSlice: number[] = [];

      for (let y = boundaries.minY; y <= boundaries.maxY; y++) {
        const { start, end } = pairCoordsToPairIndexes(boundaries.minX, y);

        pairSlice = [...pairSlice, ...pairs.slice(start, end)];
      }

      const uintArr = new Uint8Array(pairSlice);

      return dispatch(receiveChunk(center, uintArr.buffer));
    }
  };

  return ret as any;
}

export function awaitPixelUpdates(pixels: IPixelPlacement[]): Action {
  return {
    type: 'AWAIT_PIXEL_UPDATES',
    pixels,
  };
}

export function cancelPixelUpdates(pixels: IPixelPlacement[]): Action {
  return {
    type: 'CANCEL_PIXEL_UPDATES',
    pixels,
  };
}

export function receivePixelUpdate(pixelIndex: number, color: ColorIndex): Action {
  return {
    type: 'RECEIVE_PIXEL_UPDATE',
    pixelIndex,
    color,
  };
}

export function receivePixelUpdates(pixels: IPaintParamsPixel[]): Action {
  return {
    type: 'RECEIVE_PIXEL_UPDATES',
    pixels,
  };
}

export function setPaintFn(paint: PaintFn): Action {
  return {
    type: 'SET_PAINT_FN',
    paint,
  };
}

export function setWallet(wallet: WalletContextState): Action {
  return {
    type: 'SET_WALLET',
    wallet,
  };
}

export function receiveSolcanvasState(solcanvas: ISolcanvasState): Action {
  return {
    type: 'RECEIVE_SOLCANVAS_STATE',
    solcanvas,
  };
}

export function fetchSolcanvasState(fetcher: () => Promise<ISolcanvasState>): PromiseAction {
  return async (dispatch: Dispatch) => {
    const res = await fetcher();

    return dispatch(receiveSolcanvasState(res));
  };
}

export function setSolanaPrice(price: number): Action {
  return {
    type: 'SET_SOLANA_PRICE',
    price,
  };
}

export function setCanvasElement(canvas: HTMLCanvasElement): Action {
  return {
    type: 'SET_CANVAS_ELEMENT',
    canvas,
  };
}

export function setOnlineUsers(onlineUsers: number): Action {
  return {
    type: 'SET_ONLINE_USERS',
    onlineUsers,
  };
}

export function setShowConnect(show: boolean): Action {
  return {
    type: 'SET_SHOW_CONNECT',
    show,
  };
}

export function setShowHelp(show: boolean): Action {
  return {
    type: 'SET_SHOW_HELP',
    show,
  };
}

export function setShowSettings(show: boolean): Action {
  return {
    type: 'SET_SHOW_SETTINGS',
    show,
  };
}

export function setQueueingPixels(queueingPixels: boolean): Action {
  return {
    type: 'SET_QUEUEING_PIXELS',
    queueingPixels,
  };
}

export function queuePixel(coords: Cell, color: ColorIndex): Action {
  return {
    type: 'QUEUE_PIXEL',
    coords,
    color,
  };
}

export function clearPixelQueue(): Action {
  return {
    type: 'CLEAR_PIXEL_QUEUE',
  };
}

export function setIsDesktop(isDesktop: boolean): Action {
  return {
    type: 'SET_IS_DESKTOP',
    isDesktop,
  };
}

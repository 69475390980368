import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Popup } from 'semantic-ui-react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletIcon } from '@solana/wallet-adapter-react-ui';

import { copyToClipboard } from '../utils';
import { setShowConnect } from '../actions';
import { State } from '../reducers';
import WalletModal from './WalletModal';

export default function WalletButton() {
  const dispatch = useDispatch();
  const wallet = useWallet();
  const showModal = useSelector<State, boolean>((state) => state.gui.showConnect);

  const address = useMemo(() => {
    if (wallet.publicKey) {
      const publicKey = wallet.publicKey?.toBase58() || '';
      return `${(publicKey || '').substr(0, 4)}...${(publicKey || '').substr(-4)}`;
    }

    return '';
  }, [wallet.publicKey]);

  const handleConnect = useCallback(() => {
    dispatch(setShowConnect(true));
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(setShowConnect(false));
  }, [dispatch]);

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(wallet.publicKey!.toBase58());
  }, [wallet.publicKey]);

  const handleDisconnect = useCallback(() => {
    wallet.disconnect();
  }, [wallet]);

  return (
    <Container>
      <ButtonContainer>
        {wallet.connected ? (
          <Popup
            position="right center"
            size="huge"
            trigger={<WalletIcon wallet={wallet.wallet} />}
            on="click"
            inverted
          >
            <Button inverted onClick={handleCopyToClipboard}>
              Copy address
            </Button>
            <Button inverted onClick={handleDisconnect}>
              Disconnect
            </Button>
            <br />
          </Popup>
        ) : (
          <Button icon="plug" inverted circular size="big" title="Connect wallet" onClick={handleConnect} />
        )}
      </ButtonContainer>
      <span className="address">{address}</span>
      <WalletModal open={showModal} onClose={handleClose} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .address {
    font-size: 0.9rem;
  }
`;

const ButtonContainer = styled.div`
  cursor: pointer;
`;

import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import localForage from 'localforage';

import audio from './audio';
import analytics from './analytics';
import array from './array';
import promise from './promise';
import title from './title';
import reducers from '../reducers';

const isDebuggingInChrome = (window as any).__DEV__ && !!window.navigator.userAgent;

const logger = createLogger({
  predicate: (getState, action) => isDebuggingInChrome,
  collapsed: true,
  duration: true,
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    blacklist: ['canvas', 'solcanvas'],
  },
  reducers
);

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(thunk, promise, array, audio, title, analytics, logger))
);

export default function configureStore(onComplete?: () => void) {
  const persistor = persistStore(store as any, null, onComplete);

  if (isDebuggingInChrome) {
    (window as any).store = store;
  }
  return {
    store,
    persistor,
  };
}

import { useSelector } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { State } from '../reducers';
interface IProps {
  className?: string;
}

export default function ZoomBox({ className }: IProps) {
  const zoom = useSelector<State, number>((state) => state.canvas.scale);

  return (
    <Popup
      content={
        <span>
          Magnification level. <i>Magnificent!</i>
        </span>
      }
      position="right center"
      size="huge"
      inverted
      trigger={
        <Container className={className}>
          <Icon name="magnify" size="big" />
          <div className="value">x{zoom.toFixed(2)}</div>
        </Container>
      }
    ></Popup>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .value {
    font-size: 1.3rem;
  }
`;

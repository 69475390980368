import { CHUNK_BUFFER_SIZE, CHUNK_SIZE, COLORS_ABGR } from '../constants';
import { Cell, ColorIndex, AChunkRGB, ChunkId } from '../types';

const CHUNK_AREA = CHUNK_SIZE * CHUNK_SIZE;

function colorsFromChunkBuffer(chunkBuffer: Uint8Array): Uint8Array {
  const colors = new Uint8Array(CHUNK_AREA);
  const buffer = chunkBuffer;

  for (let i = 0; i < CHUNK_BUFFER_SIZE; i += 1) {
    const value = buffer[i];

    let color = value >> 4;
    colors[2 * i] = color;

    color = value & 0b0000_1111;
    colors[2 * i + 1] = color;
  }

  return colors;
}

export class ChunkRGB extends AChunkRGB {
  cell: Cell;
  key: string;
  imageData: ImageData;
  intView: Uint32Array;

  constructor(cell: Cell) {
    super();
    try {
      this.imageData = new ImageData(CHUNK_SIZE, CHUNK_SIZE);
    } catch (e) {
      // workaround when ImageData is unavailable (Such as under MS Edge)
      const $canvas = document.createElement('canvas');
      $canvas.width = CHUNK_SIZE;
      $canvas.height = CHUNK_SIZE;
      const ctx = $canvas.getContext('2d')!;
      this.imageData = ctx.getImageData(0, 0, CHUNK_SIZE, CHUNK_SIZE);
    }
    this.intView = new Uint32Array(this.imageData.data.buffer);
    this.cell = cell;
    this.key = ChunkRGB.getKey(cell);
  }

  from(chunkBuffer: Uint8Array) {
    const colors = colorsFromChunkBuffer(chunkBuffer);
    colors.forEach((color, index) => {
      this.intView[index] = COLORS_ABGR[color];
    }, this);
  }

  static getKey([x, y]: ChunkId) {
    return `${x}:${y}`;
  }

  static getIndexFromCell([x, y]: Cell): number {
    return x + CHUNK_SIZE * y;
  }

  hasColorIn(cell: Cell, color: ColorIndex): boolean {
    const index = ChunkRGB.getIndexFromCell(cell);

    const oldColor = this.intView[index];
    const newColor = COLORS_ABGR[color];
    return oldColor === newColor;
  }

  setColor(cell: Cell, color: ColorIndex): boolean {
    if (this.hasColorIn(cell, color)) {
      return false;
    }
    // apply color
    const index = ChunkRGB.getIndexFromCell(cell);
    this.intView[index] = COLORS_ABGR[color];
    return true;
  }
}

import styled from 'styled-components';

interface IProps {
  children: React.ReactNode;
}

export default function Kbd({ children }: IProps) {
  return <Span>{children}</Span>;
}

const Span = styled.span`
  font-family: Kbd212;
  font-size: 44px;
  vertical-align: middle;
`;

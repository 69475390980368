import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Segment, Sidebar } from 'semantic-ui-react';

import { State } from '../reducers';
import DownloadButton from './DownloadButton';
import HelpButton from './HelpButton';
import SettingsButton from './SettingsButton';
import WalletButton from './WalletButton';
import PriceBox from './PriceBox';
import ZoomBox from './ZoomBox';
import CoordinatesBox from './CoordinatesBox';
import OnlineUsersBox from './OnlineUsersBox';
import MainLogo from './MainLogo';
import EnvBox from './EnvBox';

export default function SideMenu() {
  const isDesktop = useSelector<State, boolean>((state) => state.gui.isDesktop);

  return (
    <Container>
      <Sidebar as={Segment} visible={true} vertical inverted width="very thin">
        <Content>
          <MainLogo className="mainLogo" />
          <Buttons>
            <HelpButton />
            {isDesktop && <SettingsButton />}
            <DownloadButton />
            {isDesktop && <WalletButton />}
          </Buttons>
          <FlexSpacer />
          <Legend>
            <OnlineUsersBox />
            {isDesktop && <PriceBox />}
            {isDesktop && <ZoomBox />}
            <CoordinatesBox />
          </Legend>
          <EnvBox />
        </Content>
      </Sidebar>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  z-index: 100;

  .ui.inverted.segment {
    /* background-color: rgba(76, 39, 109, 0.8); */
    background-color: rgba(0, 0, 0, 0.7);
  }

  .mainLogo {
    margin-bottom: 2rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const FlexSpacer = styled.div`
  flex-grow: 1;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
`;

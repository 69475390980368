import { Label } from 'semantic-ui-react';

import { SOLANA_ENV } from '../env';

const clusterColors = {
  'mainnet-beta': 'purple',
  mainnet: 'purple',
  testnet: 'blue',
  devnet: 'orange',
};

const labelColor = clusterColors[SOLANA_ENV as any];

export default function EnvBox() {
  if (SOLANA_ENV.startsWith('mainnet')) {
    return null;
  }

  return (
    <Label size="tiny" color={labelColor}>
      {SOLANA_ENV}
    </Label>
  );
}

import * as anchor from '@project-serum/anchor';
import { WalletContextState } from '@solana/wallet-adapter-react';

import { Action } from '../actions';
import { ISolcanvasState, PaintFn } from '../types';
import { SOLCANVAS_PAIRS_LENGTH } from '../constants';

export interface ISolcanvasStore extends ISolcanvasState {
  initialized: boolean;
  wallet: WalletContextState;
  paint: PaintFn;
}

const initialState: ISolcanvasStore = {
  initialized: false,
  beneficiary: anchor.web3.SystemProgram.programId,
  priceLamports: 0,
  pairs: new Array<number>(SOLCANVAS_PAIRS_LENGTH).fill(0),
  wallet: {} as any,
  paint: () => Promise.resolve(),
};

export default function solcanvas(state: ISolcanvasStore = initialState, action: Action): ISolcanvasStore {
  switch (action.type) {
    case 'RECEIVE_SOLCANVAS_STATE': {
      const { solcanvas } = action;

      return {
        ...state,
        ...solcanvas,
        initialized: true,
      };
    }
    case 'RECEIVE_PIXEL_UPDATE': {
      const { pairs } = state;
      const { pixelIndex, color } = action;

      const pairIndex = Math.floor(pixelIndex / 2);
      const isLeft = pixelIndex % 2 === 0;

      let pairColor = pairs[pairIndex];

      const leftMask = 0b1111_0000;
      const rightMask = 0b0000_1111;

      if (isLeft) {
        // new color is on the left, so leave only existing bits on the right
        pairColor = pairColor & rightMask;
        pairColor = pairColor + (color << 4);
      } else {
        pairColor = pairColor & leftMask;
        pairColor = pairColor + color;
      }

      pairs[pairIndex] = pairColor;

      return {
        ...state,
        pairs,
      };
    }
    case 'SET_PAINT_FN': {
      const { paint } = action;

      return {
        ...state,
        paint,
      };
    }
    case 'SET_WALLET': {
      const { wallet } = action;

      return {
        ...state,
        wallet,
      };
    }
    default: {
      return state;
    }
  }
}

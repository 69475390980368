import { useCallback, useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Button, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import FullPageModal from './FullPageModal';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export default function WalletModal({ open, onClose }: IProps) {
  const wallet = useWallet();
  const [initConnect, setInitConnect] = useState(false);

  const handleConnect = useCallback(
    (walletName: string) => {
      wallet.select(walletName as any);
      setInitConnect(true);
    },
    [wallet]
  );

  // wallet.select and wallet.connect need to be split up this way
  // because wallet object will be updated internally after select
  // and we need to get the updated wallet from useWallet after the update
  // to be able to connect
  useEffect(() => {
    if (wallet.wallet && initConnect) {
      connect();
    }

    async function connect() {
      try {
        await wallet.connect();
      } catch (e) {
        console.error('connect', e);
      } finally {
        setInitConnect(false);
        setTimeout(onClose, 300);
      }
    }
  }, [initConnect, onClose, wallet]);

  return (
    <FullPageModal open={open}>
      <Content>
        <Header as="h1" className="title">
          Connect Wallet
        </Header>
        <div className="wallets">
          {wallet.wallets.map((wal) => (
            <Button
              className="walletButton"
              key={wal.name}
              as="div"
              color="black"
              size="massive"
              onClick={() => handleConnect(wal.name)}
            >
              <img className="walletIcon" src={wal.icon} alt="Wallet icon" /> {wal.name}
            </Button>
          ))}
        </div>
        <Button color="black" size="massive" onClick={onClose}>
          Cancel
        </Button>
      </Content>
    </FullPageModal>
  );
}

const Content = styled.div`
  font-size: 1.4rem;
  padding: 3rem;
  max-width: 640px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 2rem;
  }

  .wallets {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .walletButton {
    min-width: 24rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .walletIcon {
    width: 2rem;
    margin-right: 2rem;
  }
`;

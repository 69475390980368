import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { State } from '../reducers';
import { Cell } from '../types';
import { screenToWorld } from '../utils';

interface IProps {
  className?: string;
}

export default function CoordinatesBox({ className }: IProps) {
  const hover = useSelector<State, Cell | undefined>((state) => state.gui.hover);
  const view = useSelector<State, Cell>((state) => state.canvas.view);
  const scale = useSelector<State, number>((state) => state.canvas.scale);
  const canvasElement = useSelector<State, HTMLCanvasElement | undefined>((state) => state.canvas.canvasElement);
  const [x, y] = useMemo(() => {
    const coords =
      hover && canvasElement
        ? screenToWorld({
            canvasElement,
            scale,
            view,
            coords: hover,
          })
        : (view.map(Math.round) as Cell);

    return coords;
  }, [canvasElement, hover, scale, view]);

  return (
    <Popup
      content={
        <span>
          Position on the canvas.
          <br />
          <i>Don't get lost!</i>
        </span>
      }
      position="right center"
      size="huge"
      inverted
      trigger={
        <Container className={className}>
          <Icon name="map marker alternate" size="big" />
          <div className="value">[{x}</div>
          <div className="value">{y}]</div>
        </Container>
      }
    ></Popup>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .value {
    font-size: 1.3rem;
  }
`;

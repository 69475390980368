import { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';

import { SOLANA_ENV, SOLANA_ENDPOINT, SOLANA_WS_ENDPOINT } from './env';

interface IProps {
  children: React.ReactNode;
}

const config = { wsEndpoint: SOLANA_WS_ENDPOINT };

export default function SolanaProviders({ children }: IProps) {
  const network = useMemo(() => SOLANA_ENV, []);
  // const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
      getSlopeWallet(),
      // getTorusWallet({
      //   options: { clientId: 'Get a client ID @ https://developer.tor.us' },
      // }),
      getLedgerWallet(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={SOLANA_ENDPOINT} config={config}>
      <WalletProvider wallets={wallets}>{children}</WalletProvider>
    </ConnectionProvider>
  );
}

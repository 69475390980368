export const coingeckoApi = {
  async fetchSolanaPrice() {
    const res = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd', {
      headers: { Accept: 'application/json' },
    });
    const json = await res.json();

    return json.solana.usd;
  },
};

import React from 'react';
import styled from 'styled-components';
import { Dimmer, Transition } from 'semantic-ui-react';

interface IProps {
  open: boolean;
  children: React.ReactNode;
  zIndex?: number;
}

export default function FullPageModal({ open, children, zIndex = 101 }: IProps) {
  return (
    <Transition visible={open} animation="fade" duration="300" unmountOnHide>
      <Dimmer active style={{ zIndex }} page inverted>
        <Content>{children}</Content>
      </Dimmer>
    </Transition>
  );
}

const Content = styled.div`
  color: black;
`;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setOnlineUsers } from '../actions';
import { SECONDS_MILLIS } from '../constants';
import { SERVER_URL, SOLANA_ENV } from '../env';

export function useOnlineUsers() {
  const dispatch = useDispatch();
  const [ws, setWs] = useState<WebSocket>();
  const [open, setOpen] = useState(false);

  const initWs = useCallback(() => {
    console.log('init WS');
    try {
      setWs(undefined);
      setOpen(false);
      let _ws = new WebSocket(SERVER_URL, SOLANA_ENV);

      _ws.onopen = () => setOpen(true);

      setWs(_ws);
    } catch (e) {
      // this connection is non critical so let's just eat the error
      console.error(e);
    }
  }, []);

  useEffect(() => {
    initWs();
  }, [initWs]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (msg) => {
        console.log('updating online users', msg.data);
        dispatch(setOnlineUsers(Number(msg.data)));
      };
    }
  }, [dispatch, initWs, ws]);

  useEffect(() => {
    if (ws && open) {
      const interval = setInterval(() => ws.send(''), 30 * SECONDS_MILLIS);
      try {
        ws.send('');
      } catch (e) {
        console.error(e);
      }

      return () => clearInterval(interval);
    }
  }, [open, ws]);

  useEffect(() => {
    if (ws && open) {
      ws.onclose = () => {
        console.log('WS connection closed');
        initWs();
      };

      ws.onerror = (e) => {
        console.log('WS connection error', e);
        initWs();
      };
    }
  }, [initWs, open, ws]);
}

import { createGlobalStyle } from 'styled-components';
import 'semantic-ui-css/semantic.min.css';

// weird hack to make prettier recognize css in createGlobalStyle and format it
const styled = {
  div: createGlobalStyle,
};

const GlobalStyle = styled.div`
  @font-face {
    font-family: Kbd212;
    src: url('212Keyboard.otf');
  }

  * {
    box-sizing: border-box;
  }

  body,
  html {
    font-size: 10px;
    font-weight: 300;
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow: hidden;
    font-family: proxima-nova, sans-serif;
  }

  #root {
    font-size: 1.5rem;
  }

  a,
  a:hover,
  a:visited,
  a:active,
  a:focus {
    color: #9945ff;
  }

  .ui.dimmer.inverted > .content {
    color: black;
    > * {
      color: black;
    }
  }
`;

export default GlobalStyle;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSolanaPrice } from '../actions';

import { coingeckoApi } from '../api';
import { MINUTE_MILLIS } from '../constants';

export function useSolanaPriceFetcher() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function refreshPrice() {
      try {
        const price = await coingeckoApi.fetchSolanaPrice();

        dispatch(setSolanaPrice(price));
      } catch (e) {
        console.error(e);
      }
    }

    const intervalId = setInterval(refreshPrice, 5 * MINUTE_MILLIS);
    refreshPrice();

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch]);
}

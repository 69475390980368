import { combineReducers } from 'redux';

import audio, { AudioState } from './audio';
import canvas, { CanvasState } from './canvas';
import gui, { GUIState } from './gui';
import solcanvas, { ISolcanvasStore } from './solcanvas';

export type State = {
  audio: AudioState;
  canvas: CanvasState;
  gui: GUIState;
  solcanvas: ISolcanvasStore;
};

export default combineReducers({
  audio,
  canvas,
  gui,
  solcanvas,
});

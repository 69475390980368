import { Action } from '../actions/types';

export default function track(action: Action): void {
  const ga = (window as any).ga;
  if (typeof ga === 'undefined') return;

  switch (action.type) {
    case 'PLACE_PIXEL': {
      const [x, y] = action.coords;
      ga('send', {
        hitType: 'event',
        eventCategory: 'Place',
        eventAction: action.color,
        eventLabel: `${x},${y}`,
      });
      break;
    }

    default:
    // nothing
  }
}

import { Action } from '../actions';
import { Cell, ColorIndex } from '../types';

export type GUIState = {
  showGrid: boolean;
  selectedColor: ColorIndex;
  hover?: Cell;
  pixelsPlaced: number;
  autoZoomIn: boolean;
  solanaPrice: number;
  onlineUsers?: number;
  showConnect: boolean;
  showHelp: boolean;
  showSettings: boolean;
  helpSeen: boolean;
  isDesktop: boolean;
};

const initialState: GUIState = {
  showGrid: false,
  selectedColor: 3,
  hover: undefined,
  pixelsPlaced: 0,
  autoZoomIn: true,
  solanaPrice: 0,
  showConnect: false,
  showHelp: false,
  showSettings: false,
  helpSeen: false,
  isDesktop: true,
};

export default function gui(state: GUIState = initialState, action: Action): GUIState {
  switch (action.type) {
    case 'SET_SHOW_GRID': {
      return {
        ...state,
        showGrid: action.showGrid,
      };
    }

    case 'SET_AUTO_ZOOM_IN': {
      return {
        ...state,
        autoZoomIn: action.autoZoomIn,
      };
    }

    case 'SELECT_COLOR': {
      return {
        ...state,
        selectedColor: action.color,
      };
    }

    case 'SET_HOVER': {
      const { hover } = action;
      return {
        ...state,
        hover,
      };
    }

    // clear hover when placing a pixel
    // fixes a bug with iPad
    case 'PLACE_PIXEL': {
      let { pixelsPlaced } = state;
      pixelsPlaced += 1;
      return {
        ...state,
        hover: undefined,
        pixelsPlaced,
      };
    }

    case 'UNSET_HOVER': {
      return {
        ...state,
        hover: undefined,
      };
    }

    case 'SET_SOLANA_PRICE': {
      return {
        ...state,
        solanaPrice: action.price,
      };
    }

    case 'SET_ONLINE_USERS': {
      return {
        ...state,
        onlineUsers: action.onlineUsers,
      };
    }

    case 'SET_SHOW_CONNECT': {
      return {
        ...state,
        showConnect: action.show,
      };
    }

    case 'SET_SHOW_HELP': {
      return {
        ...state,
        showHelp: action.show,
        helpSeen: action.show || state.helpSeen,
      };
    }

    case 'SET_SHOW_SETTINGS': {
      return {
        ...state,
        showSettings: action.show,
      };
    }

    case 'SET_IS_DESKTOP': {
      return {
        ...state,
        isDesktop: action.isDesktop,
      };
    }

    default:
      return state;
  }
}

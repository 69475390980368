import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import Hammer from 'hammerjs';
import keycode from 'keycode';
import { WalletContextState } from '@solana/wallet-adapter-react';
import styled from 'styled-components';

import { getChunkOfPixel, getClampedChunkOfPixel, isPixelWithinBounds, screenToWorld } from '../utils';
import { State } from '../reducers';
import {
  clearPixelQueue,
  fetchChunk,
  moveEast,
  moveNorth,
  moveSouth,
  moveWest,
  setCanvasElement,
  setHover,
  setQueueingPixels,
  setScale,
  setView,
  toggleGrid,
  toggleMute,
  tryFlushPixelQueue,
  tryPlacePixel,
  unsetHover,
  zoomIn,
  zoomOut,
} from '../actions';
import { render, initCanvas, ChunkRGB } from '../renderer';
import { CHUNK_SIZE } from '../constants';
import { Cell } from '../types';
import { cellsEqual } from '../utils';

export default function PixelCanvas() {
  const store = useStore<State>();
  const canvas = useSelector<State, HTMLCanvasElement | undefined>((state) => state.canvas.canvasElement);
  const wallet = useSelector<State, WalletContextState>((state) => state.solcanvas.wallet);
  const showHelp = useSelector<State, boolean>((state) => state.gui.showHelp);
  const showConnectModal = useSelector<State, boolean>((state) => state.gui.showConnect);
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const solcanvasInitialized = useSelector<State>((state) => state.solcanvas.initialized);

  const onCanvasRefChange = useCallback(
    (canvas) => {
      if (canvas) {
        dispatch(setCanvasElement(canvas));
      }
    },
    [dispatch]
  );

  const resizeViewport = useCallback(() => {
    canvas!.width = window.innerWidth;
    canvas!.height = window.innerHeight;
  }, [canvas]);

  const handleViewFinishChange = useCallback(() => {
    const state: State = store.getState();
    const { view } = state.canvas;

    // round pixel, to delete decimals!
    const [x, y] = view.map(Math.round);

    // url coordinates
    if (window.history.pushState) {
      const currentView = (window.history.state && window.history.state.view) || [0, 0];
      const [curX, curY] = currentView;

      if (curX !== x || curY !== y) {
        const newurl = `/@${x},${y}`;
        window.history.pushState({ view: [x, y] }, `(${x}, ${y})`, newurl);
      }
    }
  }, [store]);

  const initViewport = useCallback(() => {
    // track hover
    canvas!.onmousemove = ({ clientX, clientY }: MouseEvent) => {
      dispatch(setHover([clientX, clientY]));
    };
    canvas!.onmouseout = () => {
      dispatch(unsetHover());
    };

    // fingers controls on touch
    const hammertime = new Hammer(canvas);
    // Zoom-in Zoom-out in touch devices
    hammertime.get('pinch').set({ enable: true });

    // TODO: is it possible to do solana payments in mobile browser?

    hammertime.on('tap', ({ center }) => {
      const state = store.getState();
      const { autoZoomIn, selectedColor } = state.gui;
      const { scale, view, canvasElement } = state.canvas;

      const { x, y } = center;
      const cell = screenToWorld({
        canvasElement: canvasElement!,
        coords: [x, y],
        scale,
        view,
      });

      if (autoZoomIn && scale < 8) {
        dispatch(setView(cell));
        dispatch(setScale(12));
        return;
      }

      if (!isPixelWithinBounds(cell)) {
        return;
      }

      dispatch(tryPlacePixel(cell, selectedColor));
    });

    // http://stackoverflow.com/questions/18011099/pinch-to-zoom-using-hammer-js
    const initialState: State = store.getState();
    let lastScale = initialState.canvas.scale;
    let [lastPosX, lastPosY] = initialState.canvas.view;
    hammertime.on('panstart pinchstart pan pinch panend pinchend', ({ type, deltaX, deltaY, scale }) => {
      canvas!.style.cursor = 'move'; // like google maps

      // pinch start
      if (type === 'pinchstart') {
        const { scale: initScale } = store.getState().canvas;
        lastScale = Math.round(initScale);
      }

      // panstart
      if (type === 'panstart') {
        const { view: initView } = store.getState().canvas;
        [lastPosX, lastPosY] = initView;
      }

      // pinch
      if (type === 'pinch') {
        dispatch(setScale(lastScale * scale));
      }

      const { scale: viewportScale } = store.getState().canvas;

      // pan
      dispatch(setView([lastPosX - deltaX / viewportScale, lastPosY - deltaY / viewportScale]));

      // pinch end
      if (type === 'pinchend') {
        lastScale = Math.round(viewportScale);
        dispatch(setScale(lastScale));
      }

      // panend
      if (type === 'panend') {
        handleViewFinishChange();
        const { view } = store.getState().canvas;
        [lastPosX, lastPosY] = view;
        canvas!.style.cursor = 'inherit';
        return;
      }
    });

    resizeViewport();
    window.addEventListener('resize', resizeViewport);
  }, [canvas, dispatch, handleViewFinishChange, resizeViewport, store]);

  const initChunk = useCallback(() => {
    const initialState: State = store.getState();
    const { view } = initialState.canvas;
    const chunkCenter = getClampedChunkOfPixel(view);

    store.dispatch(fetchChunk(chunkCenter) as any);
  }, [store]);

  const moveChunk = useCallback(
    (() => {
      let moveLastCell: Cell;
      let moveLastScale: number;

      return function moveChunk(state: State) {
        const { view, scale, chunks, requested, canvasElement } = state.canvas;

        // check if new chunks could be seen
        const cell = getChunkOfPixel(view);
        if (cellsEqual(cell, moveLastCell) && scale >= moveLastScale) {
          return;
        }
        moveLastCell = cell;
        moveLastScale = scale;

        const pixelTopLeft = screenToWorld({
          canvasElement: canvasElement!,
          coords: [0, 0],
          scale: state.canvas.scale,
          view: state.canvas.view,
        });
        const [cx, cy] = getClampedChunkOfPixel([pixelTopLeft[0] - CHUNK_SIZE, pixelTopLeft[1] - CHUNK_SIZE]);

        const width = Math.ceil(canvas!.width / CHUNK_SIZE) + 2;
        const height = Math.ceil(canvas!.height / CHUNK_SIZE) + 2;
        const missing: Set<Cell> = new Set();

        for (let dx = 0; dx < width; dx += 1) {
          for (let dy = 0; dy < height; dy += 1) {
            const key = ChunkRGB.getKey([cx + dx, cy + dy]);
            if (!chunks[key]) {
              missing.add([cx + dx, cy + dy]);
            }
          }
        }

        if (missing.size === 0) {
          return;
        }

        missing.forEach((cell) => {
          const key = ChunkRGB.getKey(cell);
          if (!requested[key]) {
            store.dispatch(fetchChunk(cell) as any);
          }
        });
      };
    })(),
    [store, canvas]
  );

  const animationLoop = useCallback(() => {
    window.requestAnimationFrame(animationLoop);
    render(store.getState(), canvas!);
  }, [canvas, store]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      switch (keycode(event)) {
        case 'up':
        case 'w':
          dispatch(moveNorth() as any);
          break;
        case 'left':
        case 'a':
          dispatch(moveWest() as any);
          break;
        case 'down':
        case 's':
          dispatch(moveSouth() as any);
          break;
        case 'right':
        case 'd':
          dispatch(moveEast() as any);
          break;
        case 'space':
        case 'g':
          dispatch(toggleGrid());
          return;
        case 'p':
          canvas!.click();
          return;
        case 'm':
          dispatch(toggleMute());
          return;
        case '=':
        case '+':
        case 'i':
          dispatch(zoomIn() as any);
          return;
        case '-':
        case 'o':
          dispatch(zoomOut() as any);
          return;
        case 'ctrl':
        case 'left command':
        case 'right command':
          dispatch(setQueueingPixels(true));
          return;
        default:
          return;
      }
      handleViewFinishChange();
    },
    [canvas, dispatch, handleViewFinishChange]
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      switch (keycode(event)) {
        case 'ctrl':
        case 'left command':
        case 'right command':
          dispatch(setQueueingPixels(false));
          dispatch(tryFlushPixelQueue());
          return;
        default:
          return;
      }
    },
    [dispatch]
  );

  const initKeypressHandlers = useCallback(() => {
    window.addEventListener('keydown', handleKeyPress, false);
    window.addEventListener('keyup', handleKeyUp, false);
  }, [handleKeyPress, handleKeyUp]);

  const handleMouseWheelEvent = useCallback(
    ({ deltaY }: WheelEvent) => {
      if (deltaY < 0) {
        dispatch(zoomIn() as any);
      }

      if (deltaY > 0) {
        dispatch(zoomOut() as any);
      }
    },
    [dispatch]
  );

  const initMouseWheelHandlers = useCallback(() => {
    document.addEventListener('wheel', handleMouseWheelEvent, false);
  }, [handleMouseWheelEvent]);

  useEffect(() => {
    if (wallet.connected) {
      dispatch(tryFlushPixelQueue());
    } else {
      dispatch(clearPixelQueue());
    }
  }, [dispatch, wallet.connected]);

  useEffect(() => {
    if (!wallet.connecting) {
      dispatch(clearPixelQueue());
    }
  }, [dispatch, wallet.connecting]);

  useEffect(() => {
    console.log({ showConnectModal, connecting: wallet.connecting, connected: wallet.connected });
    if (!showConnectModal && !wallet.connecting && !wallet.connected) {
      dispatch(clearPixelQueue());
    }
  }, [dispatch, showConnectModal, wallet.connected, wallet.connecting]);

  useEffect(() => {
    if (canvas && solcanvasInitialized && !initialized) {
      initViewport();
      initChunk();
      initCanvas();
      initKeypressHandlers();
      initMouseWheelHandlers();
      animationLoop();

      store.subscribe(() => {
        const state: State = store.getState();

        moveChunk(state);
      });

      // force an initial rerender
      moveChunk(store.getState());
      setInitialized(true);
    }
  }, [
    animationLoop,
    canvas,
    initChunk,
    initKeypressHandlers,
    initMouseWheelHandlers,
    initViewport,
    initialized,
    moveChunk,
    solcanvasInitialized,
    store,
  ]);

  return (
    <Container>
      <canvas id="pixelCanvas" ref={onCanvasRefChange} />
      <Dimmer active={!solcanvasInitialized && !showHelp} page blur inverted style={{ zIndex: 10 }}>
        <DimmerContainer>
          <Loader size="massive" />
        </DimmerContainer>
      </Dimmer>
    </Container>
  );
}

const Container = styled.div``;

const DimmerContainer = styled.div`
  position: relative;

  .ui.loader {
    transform: scale(1.5);
  }
`;

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';

export const PROGRAM_ID = process.env.REACT_APP_PROGRAM_ID as string;

export const SOLANA_ENV = (() => {
  const processEnv = process.env.REACT_APP_SOLANA_ENV;
  if (processEnv) {
    return processEnv;
  }

  if (window.location.hostname.startsWith('dev')) {
    return 'devnet';
  }

  return 'mainnet-beta';
})() as WalletAdapterNetwork;

export const SOLANA_ENDPOINT = (() => {
  const processEndpoint = process.env.REACT_APP_SOLANA_ENDPOINT as string;
  if (processEndpoint) {
    return processEndpoint;
  }

  return clusterApiUrl(SOLANA_ENV);
})();

export const SOLANA_WS_ENDPOINT = SOLANA_ENDPOINT.replace('https:', 'wss:');

export const SOLANA_CANVAS_PROGRAM_ID = process.env.REACT_APP_SOLANA_CANVAS_PROGRAM_ID as string;
export const SOLANA_PIXELS_ADDRESS = process.env.REACT_APP_SOLANA_PIXELS_ADDRESS as string;

export const SERVER_URL = process.env.REACT_APP_SERVER_URL as string;

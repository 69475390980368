import * as anchor from '@project-serum/anchor';

export abstract class AChunkRGB {
  abstract cell: Cell;
  abstract key: string;
  abstract imageData: ImageData;
  abstract intView: Uint32Array;

  abstract from(chunkBuffer: Uint8Array): void;

  abstract hasColorIn(cell: Cell, color: ColorIndex): boolean;

  abstract setColor(cell: Cell, color: ColorIndex): boolean;
}

export type Index = number; // TODO integer >= 0
export type ChunkId = [number, number];
export type Cell = [number, number];
export type Chunks = { [key: string]: AChunkRGB };

export type ColorIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
export type Color = string;

export interface ISolcanvasState {
  priceLamports: number;
  beneficiary: anchor.web3.PublicKey;
  pairs: number[];
}

export interface IPaintParamsPixel {
  index: number;
  color: ColorIndex;
}

export interface IPaintParams {
  pixels: IPaintParamsPixel[];
  beneficiary: anchor.web3.PublicKey;
  value: number;
}

export type PaintFn = (params: IPaintParams) => Promise<void>;

export interface IPixelPlacement {
  coords: Cell;
  color: ColorIndex;
}

export type IPixelPlacements = { [key: string]: IPixelPlacement };

import { State } from '../reducers';

import { ChunkRGB } from './ChunkRGB';
import { cellsEqual, getClampedChunkOfPixel, isPixelWithinBounds } from '../utils';
import { CHUNK_SIZE, COLORS, MAX_PIXEL_QUEUE_SIZE } from '../constants';
import { Cell, ColorIndex } from '../types';
import { clamp, screenToWorld, worldToScreen } from '../utils';

const PLACEHOLDER_SIZE = 1.2;
const PLACEHOLDER_BORDER = 1;
const maxScreenSize = Math.max(window.screen.width, window.screen.height);
const computedRadius = Math.ceil((maxScreenSize / CHUNK_SIZE - 1) / 2);
export const CHUNK_RENDER_RADIUS = clamp(computedRadius, 4, 16);

let $canvas: HTMLCanvasElement;

export function initCanvas() {
  $canvas = document.createElement('canvas');

  $canvas.width = (2 * CHUNK_RENDER_RADIUS + 1) * CHUNK_SIZE;
  $canvas.height = (2 * CHUNK_RENDER_RADIUS + 1) * CHUNK_SIZE;

  const context = $canvas.getContext('2d')!;

  context.fillStyle = COLORS[0];
  context.fillRect(0, 0, $canvas.width, $canvas.height);
}

let lastFetchCount: number = 0;
let lastRenderCenterChunk: Cell;
let lastView: Cell;
export function render(state: State, $viewport: HTMLCanvasElement) {
  if (window.document.hidden) {
    return;
  }

  const { showGrid, hover, isDesktop } = state.gui;
  const { chunks, view, scale: cellSize, fetchCount } = state.canvas;

  if (!view) {
    return;
  }

  let renderCenterChunk = lastRenderCenterChunk;
  if (view !== lastView) {
    renderCenterChunk = getClampedChunkOfPixel(view);
    lastView = view;
  }

  if (!cellsEqual(lastRenderCenterChunk, renderCenterChunk) || lastFetchCount !== fetchCount) {
    // renderBoundaries();

    // if change on render center, lets render!
    // console.log('chunks render', chunks);
    renderChunks(chunks, renderCenterChunk);
    lastRenderCenterChunk = renderCenterChunk;
    lastFetchCount = fetchCount;
  }

  const viewportCtx = $viewport.getContext('2d')!;

  const { width, height } = $viewport;
  viewportCtx.fillStyle = '#000';
  // viewportCtx.clearRect(0, 0, width, height);
  viewportCtx.fillRect(0, 0, width, height);

  // Disable smoothing
  (viewportCtx as any).msImageSmoothingEnabled = false;
  (viewportCtx as any).webkitImageSmoothingEnabled = false;
  (viewportCtx as any).mozImageSmoothingEnabled = false;
  viewportCtx.imageSmoothingEnabled = false;

  // ZOOM
  // scale = (0.9 * scale) + (0.1 * scaleTarget);
  // TODO animate scale
  // TODO animate view

  // copied colorthis.space
  const [x, y] = view;
  const [cx, cy] = renderCenterChunk;
  viewportCtx.save();
  viewportCtx.translate(width / 2, height / 2);
  viewportCtx.scale(cellSize, cellSize);
  viewportCtx.drawImage(
    $canvas,
    -(CHUNK_SIZE * CHUNK_RENDER_RADIUS + (x - cx * CHUNK_SIZE)),
    -(CHUNK_SIZE * CHUNK_RENDER_RADIUS + (y - cy * CHUNK_SIZE))
  );
  viewportCtx.restore();

  if (showGrid && cellSize >= 8) {
    renderGrid(state, $viewport);
  }

  if (!isDesktop) {
    return;
  }

  renderPixelQueue(state, $viewport);
  renderPixelLoaders(state, $viewport);

  if (hover) {
    renderPlaceholder(state, $viewport);
  }
}

export function renderPixel(x: number, y: number, color: ColorIndex, chunkPosition = lastRenderCenterChunk) {
  const context = $canvas.getContext('2d')!;

  let px = 0;
  let py = 0;
  const [cx, cy] = chunkPosition;
  px += CHUNK_RENDER_RADIUS - cx;
  py += CHUNK_RENDER_RADIUS - cy;
  px *= CHUNK_SIZE;
  py *= CHUNK_SIZE;

  // now [px, py] = position of (0, 0) on screen
  // TODO use world to screen of utils
  px += x;
  py += y;

  context.fillStyle = COLORS[color];
  context.fillRect(px, py, 1, 1);
}

export function renderChunk(chunk: ChunkRGB, { chunkPosition }) {
  const context = $canvas.getContext('2d')!;

  const [cx, cy] = chunkPosition;
  let [x, y] = chunk.cell;
  x += CHUNK_RENDER_RADIUS - cx;
  y += CHUNK_RENDER_RADIUS - cy;
  x *= CHUNK_SIZE;
  y *= CHUNK_SIZE;

  // console.log('renderChunk', chunk.imageData, x, y);

  context.putImageData(chunk.imageData, x, y);
}

function clearChunk(cell: Cell, { chunkPosition }) {
  const context = $canvas.getContext('2d')!;

  const [cx, cy] = chunkPosition;
  let [x, y] = cell;
  x += CHUNK_RENDER_RADIUS - cx;
  y += CHUNK_RENDER_RADIUS - cy;
  x *= CHUNK_SIZE;
  y *= CHUNK_SIZE;

  // console.log({ cx, cy });

  // const color = isChunkWithinBounds(chunkPosition) ? COLORS[0] : COLORS[3];

  // console.log({ cx, cy, color });
  // clear space
  // context.fillStyle = color;
  context.fillStyle = '#000';
  context.fillRect(x, y, CHUNK_SIZE, CHUNK_SIZE);
}

/**
 * Optimizado para aprovechar lo maximo el render antiguo.
 * Requiere que el resto de funciones PINTEN un cuadrado blanco, envez de llamar a clearRect!
 * @param chunks
 * @param oldCenter
 * @param newCenter
 */
export function renderChunks(chunks, chunkPosition) {
  const [x, y] = chunkPosition;

  // CLEAN margin
  // draw new chunks. If not existing, just clear.
  for (let dx = -CHUNK_RENDER_RADIUS; dx <= CHUNK_RENDER_RADIUS; dx += 1) {
    for (let dy = -CHUNK_RENDER_RADIUS; dy <= CHUNK_RENDER_RADIUS; dy += 1) {
      const cx = x + dx;
      const cy = y + dy;

      // new chunk
      const key = ChunkRGB.getKey([cx, cy]);

      // chunk = chunks.get(key);
      const chunk = chunks[key];
      // console.log(chunks, chunk);
      if (chunk) {
        // render new chunk
        renderChunk(chunk, { chunkPosition });
      } else {
        // we don't have that chunk
        // just clear
        clearChunk([cx, cy], { chunkPosition });
      }
    }
  }
}

function renderGrid(state: State, $viewport: HTMLCanvasElement) {
  const { width, height } = $viewport;
  const { scale } = state.canvas;

  const viewportCtx = $viewport.getContext('2d')!;

  viewportCtx.globalAlpha = 0.1;
  viewportCtx.fillStyle = '#000';

  const world = screenToWorld({
    canvasElement: $viewport,
    coords: [0, 0],
    scale: state.canvas.scale,
    view: state.canvas.view,
  });
  let [x, y] = worldToScreen({
    canvasElement: $viewport,
    coords: world,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });

  for (; x < width; x += scale) {
    viewportCtx.fillRect(x, 0, 1, height);
  }

  for (; y < height; y += scale) {
    viewportCtx.fillRect(0, y, width, 1);
  }

  viewportCtx.globalAlpha = 1;
}

function renderPlaceholder(state: State, $viewport: HTMLCanvasElement) {
  const { scale, queueingPixels, queuedPixels } = state.canvas;
  const { selectedColor, hover } = state.gui;

  const viewportCtx = $viewport.getContext('2d')!;

  const worldPos = screenToWorld({
    canvasElement: $viewport,
    coords: hover!,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });
  const [sx, sy] = worldToScreen({
    canvasElement: $viewport,
    coords: worldPos,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });

  if (!isPixelWithinBounds(worldPos)) {
    return;
  }

  // placeholder
  viewportCtx.save();
  viewportCtx.translate(sx + scale / 2, sy + scale / 2);
  const angle = Math.sin(Date.now() / 250) / 4;
  viewportCtx.rotate(angle);
  viewportCtx.fillStyle = '#000';
  viewportCtx.fillRect(
    -(scale * (PLACEHOLDER_SIZE / 2)) - PLACEHOLDER_BORDER,
    -(scale * (PLACEHOLDER_SIZE / 2)) - PLACEHOLDER_BORDER,
    scale * PLACEHOLDER_SIZE + 2 * PLACEHOLDER_BORDER,
    scale * PLACEHOLDER_SIZE + 2 * PLACEHOLDER_BORDER
  );
  viewportCtx.fillStyle = COLORS[selectedColor];
  viewportCtx.fillRect(
    -scale * (PLACEHOLDER_SIZE / 2),
    -scale * (PLACEHOLDER_SIZE / 2),
    scale * PLACEHOLDER_SIZE,
    scale * PLACEHOLDER_SIZE
  );
  viewportCtx.restore();

  if (!queueingPixels) {
    return;
  }

  // queue size
  const queueSize = Object.keys(queuedPixels).length;
  const queueLabel = `${Object.keys(queuedPixels).length} / ${MAX_PIXEL_QUEUE_SIZE}`;
  const isMaxSize = queueSize >= MAX_PIXEL_QUEUE_SIZE;

  viewportCtx.save();
  viewportCtx.translate(sx + scale / 2, sy + scale / 2);
  viewportCtx.font = '16px sans-serif';
  viewportCtx.shadowColor = 'black';
  viewportCtx.shadowBlur = 5;
  viewportCtx.lineWidth = 3;
  viewportCtx.strokeText(queueLabel, -20, -50);
  viewportCtx.shadowBlur = 0;
  viewportCtx.fillStyle = isMaxSize ? 'tomato' : 'white';
  viewportCtx.fillText(queueLabel, -20, -50);
  // viewportCtx.fillStyle = '#000';
  // viewportCtx.globalAlpha = 0.5;
  // viewportCtx.fillRect(-25, -40, 50, 30);
  // viewportCtx.fillStyle = '#eee';
  // viewportCtx.strokeStyle = '#eee';
  // viewportCtx.font = '16px sans-serif';
  // viewportCtx.fillText(`${Object.keys(queuedPixels).length}/${MAX_PIXEL_QUEUE_SIZE}`, -20, -35);
  viewportCtx.restore();
}

function renderBoundaries() {
  const context = $canvas.getContext('2d')!;

  const { width, height } = $canvas;
  console.log({ width, height });
  context.fillStyle = '#000';
  context.fillRect(0, 0, width, height);
}

function renderPixelQueue(state: State, viewport: HTMLCanvasElement) {
  const { queuedPixels } = state.canvas;

  Object.values(queuedPixels)
    .filter(({ coords }) => isPixelWithinBounds(coords))
    .forEach(({ coords, color }) => {
      renderPixel_alpha({
        state,
        coords,
        color,
        viewport,
      });
    });
}

function renderPixelLoaders(state: State, viewport: HTMLCanvasElement) {
  const { pixelsUpdating } = state.canvas;

  Object.values(pixelsUpdating)
    .filter(({ coords }) => isPixelWithinBounds(coords))
    .forEach(({ coords, color }) => {
      renderPixel_alphaPulse({
        state,
        coords,
        color,
        viewport,
      });
    });

  // renderPixelLoader_spin({
  //   state,
  //   coords: [0, 0],
  //   color: 15,
  //   viewport,
  // });
  // renderPixelLoader_hslBorder({
  //   state,
  //   coords: [0, 2],
  //   color: 15,
  //   viewport,
  // });

  // renderPixelLoader_hslBorderAlpha({
  //   state,
  //   coords: [0, 4],
  //   color: 15,
  //   viewport,
  // });
  // renderPixelLoader_hslContent({
  //   state,
  //   coords: [0, 6],
  //   color: 15,
  //   viewport,
  // });

  // renderPixel_alphaPulse({
  //   state,
  //   coords: [0, 8],
  //   color: 15,
  //   viewport,
  // });
}

function renderPixelLoader({
  state,
  coords,
  color,
  viewport,
}: {
  state: State;
  coords: Cell;
  color: ColorIndex;
  viewport: HTMLCanvasElement;
}) {
  const { scale } = state.canvas;
  const viewportCtx = viewport.getContext('2d')!;

  const [sx, sy] = worldToScreen({
    canvasElement: viewport,
    coords: coords,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });

  viewportCtx.save();
  viewportCtx.translate(sx + scale / 2, sy + scale / 2);

  const LOADER_SIZE = 1.2;
  const LOADER_BORDER = 1;

  const angle = (((Date.now() / 5) % 360) * Math.PI) / 180;
  viewportCtx.rotate(angle);

  viewportCtx.fillStyle = '#000';
  viewportCtx.fillRect(
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER
  );

  viewportCtx.fillStyle = COLORS[color];
  viewportCtx.fillRect(
    -scale * (LOADER_SIZE / 2),
    -scale * (LOADER_SIZE / 2),
    scale * LOADER_SIZE,
    scale * LOADER_SIZE
  );
  viewportCtx.restore();
}

function renderPixelLoader_spin({
  state,
  coords,
  color,
  viewport,
}: {
  state: State;
  coords: Cell;
  color: ColorIndex;
  viewport: HTMLCanvasElement;
}) {
  const { scale } = state.canvas;
  const viewportCtx = viewport.getContext('2d')!;

  const [sx, sy] = worldToScreen({
    canvasElement: viewport,
    coords: coords,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });

  viewportCtx.save();
  viewportCtx.translate(sx + scale / 2, sy + scale / 2);

  const LOADER_SIZE = 1.2;
  const LOADER_BORDER = 1;

  const angle = (((Date.now() / 5) % 360) * Math.PI) / 180;
  viewportCtx.rotate(angle);

  viewportCtx.fillStyle = '#000';
  viewportCtx.fillRect(
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER
  );

  viewportCtx.fillStyle = COLORS[color];
  viewportCtx.fillRect(
    -scale * (LOADER_SIZE / 2),
    -scale * (LOADER_SIZE / 2),
    scale * LOADER_SIZE,
    scale * LOADER_SIZE
  );
  viewportCtx.restore();
}

function renderPixelLoader_hslBorder({
  state,
  coords,
  color,
  viewport,
}: {
  state: State;
  coords: Cell;
  color: ColorIndex;
  viewport: HTMLCanvasElement;
}) {
  const { scale } = state.canvas;
  const viewportCtx = viewport.getContext('2d')!;

  const [sx, sy] = worldToScreen({
    canvasElement: viewport,
    coords: coords,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });

  viewportCtx.save();
  viewportCtx.translate(sx + scale / 2, sy + scale / 2);

  const LOADER_SIZE = 1.2;
  const LOADER_BORDER = 3;

  const hue = Math.floor(new Date().getTime() / 10) % 360;
  const hsl = `hsl(${hue},50%,50%)`;

  viewportCtx.fillStyle = hsl;
  viewportCtx.fillRect(
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER
  );

  viewportCtx.fillStyle = COLORS[color];
  viewportCtx.fillRect(
    -scale * (LOADER_SIZE / 2),
    -scale * (LOADER_SIZE / 2),
    scale * LOADER_SIZE,
    scale * LOADER_SIZE
  );
  viewportCtx.restore();
}

function renderPixelLoader_hslBorderAlpha({
  state,
  coords,
  color,
  viewport,
}: {
  state: State;
  coords: Cell;
  color: ColorIndex;
  viewport: HTMLCanvasElement;
}) {
  const { scale } = state.canvas;
  const viewportCtx = viewport.getContext('2d')!;

  const [sx, sy] = worldToScreen({
    canvasElement: viewport,
    coords: coords,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });

  viewportCtx.save();
  viewportCtx.translate(sx + scale / 2, sy + scale / 2);
  viewportCtx.globalAlpha = 0.5;

  const LOADER_SIZE = 1.2;
  const LOADER_BORDER = 3;

  const hue = Math.floor(new Date().getTime() / 10) % 360;
  const hsl = `hsl(${hue},50%,50%)`;

  viewportCtx.fillStyle = hsl;
  viewportCtx.fillRect(
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER
  );

  viewportCtx.fillStyle = COLORS[color];
  viewportCtx.fillRect(
    -scale * (LOADER_SIZE / 2),
    -scale * (LOADER_SIZE / 2),
    scale * LOADER_SIZE,
    scale * LOADER_SIZE
  );
  viewportCtx.restore();
}

function renderPixelLoader_hslContent({
  state,
  coords,
  color,
  viewport,
}: {
  state: State;
  coords: Cell;
  color: ColorIndex;
  viewport: HTMLCanvasElement;
}) {
  const { scale } = state.canvas;
  const viewportCtx = viewport.getContext('2d')!;

  const [sx, sy] = worldToScreen({
    canvasElement: viewport,
    coords: coords,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });

  viewportCtx.save();
  viewportCtx.translate(sx + scale / 2, sy + scale / 2);

  const LOADER_SIZE = 1.2;
  const LOADER_BORDER = 3;

  const hue = Math.floor(new Date().getTime() / 10) % 360;
  const hsl = `hsl(${hue},50%,50%)`;

  viewportCtx.fillStyle = COLORS[color];
  viewportCtx.fillRect(
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER
  );

  viewportCtx.fillStyle = hsl;
  viewportCtx.fillRect(
    -scale * (LOADER_SIZE / 2),
    -scale * (LOADER_SIZE / 2),
    scale * LOADER_SIZE,
    scale * LOADER_SIZE
  );
  viewportCtx.restore();
}

function renderPixel_alphaPulse({
  state,
  coords,
  color,
  viewport,
}: {
  state: State;
  coords: Cell;
  color: ColorIndex;
  viewport: HTMLCanvasElement;
}) {
  const { scale } = state.canvas;
  const viewportCtx = viewport.getContext('2d')!;

  const [sx, sy] = worldToScreen({
    canvasElement: viewport,
    coords: coords,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });

  viewportCtx.save();
  viewportCtx.translate(sx + scale / 2, sy + scale / 2);

  const LOADER_SIZE = 1.2;
  const LOADER_BORDER = 1;

  const alpha = Math.abs(Math.cos(new Date().getTime() / 500) * 0.4) + 0.3;

  viewportCtx.globalAlpha = alpha;

  viewportCtx.fillStyle = '#000';
  viewportCtx.fillRect(
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER
  );

  viewportCtx.fillStyle = COLORS[color];
  viewportCtx.fillRect(
    -scale * (LOADER_SIZE / 2),
    -scale * (LOADER_SIZE / 2),
    scale * LOADER_SIZE,
    scale * LOADER_SIZE
  );
  viewportCtx.restore();
}

function renderPixel_alpha({
  state,
  coords,
  color,
  viewport,
}: {
  state: State;
  coords: Cell;
  color: ColorIndex;
  viewport: HTMLCanvasElement;
}) {
  const { scale } = state.canvas;
  const viewportCtx = viewport.getContext('2d')!;

  const [sx, sy] = worldToScreen({
    canvasElement: viewport,
    coords: coords,
    scale: state.canvas.scale,
    view: state.canvas.view,
  });

  viewportCtx.save();
  viewportCtx.translate(sx + scale / 2, sy + scale / 2);

  const LOADER_SIZE = 1.2;
  const LOADER_BORDER = 1;

  viewportCtx.globalAlpha = 0.4;

  viewportCtx.fillStyle = '#000';
  viewportCtx.fillRect(
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    -(scale * (LOADER_SIZE / 2)) - LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER,
    scale * LOADER_SIZE + 2 * LOADER_BORDER
  );

  viewportCtx.fillStyle = COLORS[color];
  viewportCtx.fillRect(
    -scale * (LOADER_SIZE / 2),
    -scale * (LOADER_SIZE / 2),
    scale * LOADER_SIZE,
    scale * LOADER_SIZE
  );
  viewportCtx.restore();
}

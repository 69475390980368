import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { setIsDesktop } from '../actions';

export function useResponsive() {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  useEffect(() => {
    dispatch(setIsDesktop(isDesktop));
  }, [dispatch, isDesktop]);
}

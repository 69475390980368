import { useCallback, useState } from 'react';
import { Button } from 'semantic-ui-react';

import SettingsModal from './SettingsModal';

interface IProps {
  className?: string;
}

export default function SettingsButton({ className }: IProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setModalOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <Button className={className} icon="cog" inverted circular size="big" title="Settings" onClick={handleOpen} />
      <SettingsModal open={modalOpen} onClose={handleClose} />
    </>
  );
}
